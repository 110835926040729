import { CLOSE_DELETE_MODAL, OPEN_DELETE_MODAL } from "../actions/deleteModal";

const initialState = {
  id: null,
  deletingObj: "",
  title: "",
  show: false,
  type: "",
};

const deleteModalReducer = (
  state = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case OPEN_DELETE_MODAL:
      return {
        id: action.payload.id,
        deletingObj: action.payload.deletingObj,
        title: action.payload.title,
        show: true,
        type: action.payload.type,
      };
    case CLOSE_DELETE_MODAL:
      return initialState;
    default:
      return state;
  }
};

export default deleteModalReducer;
