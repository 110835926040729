import { Route, Redirect } from "react-router-dom";

const AuthMiddleware = ({
  Component,
  loggedIn,
  path,
  exact
}: {
  Component: JSX.Element;
  loggedIn: boolean;
  path: string;
  exact: boolean
}) => {
  return (
    <Route
      path={path}
      exact={exact}
      render={() => {
        return loggedIn ? Component: <Redirect to="/" />;
      }}
    />
  );
};

export default AuthMiddleware;
