import { IEmployee } from "./../../types";
import * as actions from "../actions/employees";

interface IEmployees {
  data: IEmployee[] | [];
  loading: boolean;
  err: string | null;
  count: number;
}

const initialState: IEmployees = {
  data: [],
  loading: true,
  err: null,
  count: 0,
};

const employeesReducer = (
  state = initialState,
  action: { type: string; payload: any; count: number }
): IEmployees => {
  switch (action.type) {
    case actions.START_EMPLOYEE:
      return {
        ...initialState,
        loading: true,
      };
    case actions.SUCCESS_EMPLOYEE:
      return {
        loading: false,
        data: action.payload.data,
        count: action.payload.count,
        err: null,
      };
    case actions.ERROR_EMPLOYEE:
      return {
        ...state,
        loading: false,
        err: action.payload,
      };
    default:
      return state;
  }
};

export default employeesReducer;
