import { combineReducers } from "redux";
import archivesReducer from "./archives";
import authReducer from "./auth";
import birthdayReducer from "./birthday";
import deleteModalReducer from "./deleteModal";
import employeesReducer from "./employee";
import modalReducer from "./modal";
import organizationReducer from "./organizations";
import positionsReducer from "./positions";
import mobileReducer from "./mobile";
import { vacationsReducer } from "./vacations";
import { permissionsReducer } from "./permissions";
import permissionsModalReducer from "./permissionsModal";
import { vaccinationsModalReducer } from "./vaccinationsModal";

const rootReducer = combineReducers({
  auth: authReducer,
  positions: positionsReducer,
  employees: employeesReducer,
  modal: modalReducer,
  deleteModal: deleteModalReducer,
  birthdays: birthdayReducer,
  archives: archivesReducer,
  organizations: organizationReducer,
  mobile: mobileReducer,
  vacations: vacationsReducer,
  permissions: permissionsReducer,
  permissionsModal: permissionsModalReducer,
  vaccinationsModal: vaccinationsModalReducer
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
