import { DataGrid } from "@material-ui/data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllVaccinations } from "../api/requests";
import CustomModal from "../components/modal";
import VaccinationsModal from "../components/VaccinationsModal";
import { vaccinationColumns } from "../helpers";
import { CLOSE_MODAL, SHOW_MODAL } from "../redux/actions/vaccinationsModal";
import { authSelector, vaccinationsModalSelector } from "../redux/selectors";
import { Button, Pagination } from "@material-ui/core";
import { isStaff } from "../utils/isStaff";

const VaccinationsPage = () => {
  const [data, setData] = useState<any[]>([]);
  const dispatch = useDispatch();
  const { show } = useSelector(vaccinationsModalSelector);
  const pageSize = 100;
  const count = data.length;
  const [page, setPage] = useState(1);
  const { user } = useSelector(authSelector);
  const _isStaff = isStaff(user.role)

  const handleClose = () => {
    dispatch({
      type: CLOSE_MODAL,
    });
    getData();
  };

  const getData = async () => {
    try {
      const { data } = await getAllVaccinations({});
      setData(data.results);
    } catch {
      setData([]);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const vaccinationModalData = VaccinationsModal({ handleClose });

  return (
    <>
      {show && !_isStaff && <CustomModal {...vaccinationModalData} />}
      {_isStaff ? null : <div style={{ display: "flex", marginBottom: 25 }}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            dispatch({
              type: SHOW_MODAL,
              payload: {
                isEditing: false,
                show: true,
              },
            });
          }}
        >
          Добавить
        </Button>
      </div>}
      <div style={{ height: 650, width: "auto" }}>
        <DataGrid
          rows={data}
          page={page - 1}
          rowsPerPageOptions={[pageSize]}
          pageSize={pageSize}
          columns={vaccinationColumns}
        />
      </div>
      <div className="pagination" style={{ padding:'50px 0' }}>
        <Pagination
          count={Math.ceil(count / pageSize)}
          page={page}
          onChange={(_, newPage) => {
            setPage(newPage);
          }}
          color="primary"
        />
      </div>
    </>
  );
};

export default VaccinationsPage;
