import { Box, Button, Paper, TextField, Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Logo } from "../images/logo.svg";
import { loginUser } from "../redux/actionCreators/auth";
import { useState } from "react";
import { authSelector } from "../redux/selectors";
import CircularProgress from "@material-ui/core/CircularProgress";

const LoginPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const email = register("email", {
    required: {
      message: "Пожалуйста введите логин.",
      value: true,
    },
  });
  const password = register("password", {
    required: {
      message: "Пожалуйтса введите пароль.",
      value: true,
    },
  });
  const { loading } = useSelector(authSelector);

  return (
    <Box
      width="100vw"
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box width={400} paddingX="20px" >
        <Paper variant="outlined">
          <form
            onSubmit={handleSubmit(
              (values: { password: string; email: string }) => {
                dispatch(loginUser(values, setError));
              }
            )}
          >
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              padding="30px"
            >
              <Box margin="10px auto">
                <Logo />
              </Box>
              {error ? (
                <Typography textAlign="center" color="red">
                  Email или пароль неверный
                </Typography>
              ) : null}
              <TextField
                {...email}
                margin="normal"
                variant="outlined"
                label="Email"
                helperText={errors?.login?.message}
                error={errors.login ? true : false}
              />
              <TextField
                {...password}
                type="password"
                margin="normal"
                variant="outlined"
                label="Пароль"
                placeholder="********"
                helperText={errors?.password?.message}
                error={errors.password ? true : false}
              />
              <Button
                style={{ marginTop: 15 }}
                color="primary"
                type="submit"
                variant="contained"
                startIcon={
                  loading && <CircularProgress color="inherit" size={20} />
                }
              >
                Вход
              </Button>
            </Box>
          </form>
        </Paper>
      </Box>
    </Box>
  );
};

export default LoginPage;
