import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import { createTheme, Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/styles";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { authSelector, organizationSelector } from "../redux/selectors";
import { useState } from "react";
import { useEffect } from "react";
import { getEmployees } from "../redux/actionCreators/employees";
import { ReactComponent as ExcelIcon } from "../images/excel.svg";
import { getExportFile } from "../api/requests";
import { getArchives } from "../redux/actionCreators/archives";
import fileDownload from "js-file-download";

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        justifyContent: "space-between",
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        padding: "10px",
        ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
          gap: '20px'
        }
      },
      textField: {
        [theme.breakpoints.down("xs")]: {
          width: "100%",
        },
        margin: theme.spacing(1, 0.5, 1.5),
        "& .MuiSvgIcon-root": {
          marginRight: theme.spacing(0.5),
        },
        "& .MuiInput-underline:before": {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
      select: {
        width: 200,
        marginRight: 20,
      },
    }),
  { defaultTheme }
);

interface QuickSearchToolbarProps {
  clearSearch: () => void;
  onChange: () => void;
  value: string;
  isArchive?: boolean;
}

export const QuickSearchToolbar = (props: QuickSearchToolbarProps) => {
  const classes = useStyles();
  const { user } = useSelector(authSelector);
  const { data } = useSelector(organizationSelector);
  const [organizationId, setOrganizationId] = useState<null | number | string>(
    "all"
  );
  const [firstRender, setFirstRender] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (organizationId && !firstRender) {
      dispatch(getEmployees("", organizationId));
      dispatch(getArchives("", organizationId));
    } else {
      setFirstRender(false);
    }
    // eslint-disable-next-line
  }, [organizationId, dispatch]);

  // useEffect(() => {
  //   getExportFile().then((res) => {
  //     console.log(res);
  //     fileDownload(res.data, "сотрудники.xlsx");
  //   });
  // }, []);

  return (
    <div className={classes.root}>
      <div>
        {props?.hasOwnProperty("isArchive") ? (
          <Button
            variant="outlined"
            onClick={() => {
              const org_id =
                user?.role === "admin" ? organizationId : user?.organization;
              getExportFile(org_id, props.isArchive || false).then((res) => {
                // eslint-disable-next-line
                const org = data?.find((_org: any) => _org?.id == org_id);
                fileDownload(
                  res.data,
                  org?.name ? org?.name + ".xlsx" : "все сотрудники.xlsx"
                );
              });
            }}
            startIcon={<ExcelIcon width={25} height={25} />}
          >
            Экспортировать
          </Button>
        ) : null}
      </div>
      <div>
        {user?.role === "admin" && (
          <FormControl>
            <InputLabel id="orgSel">Организация</InputLabel>
            <Select
              className={classes.select}
              size="small"
              label="Организация"
              labelId="orgSel"
              value={organizationId}
              onChange={(event) => {
                setOrganizationId(event.target.value);
              }}
            >
              <MenuItem value="all">Все</MenuItem>
              {data.map((org: { name: string; id: number }) => {
                return (
                  <MenuItem key={org.id} value={org.id}>
                    {org.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
        <TextField
          variant="outlined"
          value={props.value}
          onChange={props.onChange}
          placeholder="Поиск..."
          className={classes.textField}
          InputProps={{
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: props.value ? "visible" : "hidden" }}
                onClick={props.clearSearch}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
        />
      </div>
    </div>
  );
};
