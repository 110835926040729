import { Dispatch } from "redux";
import { getBranchs } from "../../api/requests";
import * as actions from "../actions/organizations";

export const getOrganizations = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: actions.ORGANIZATION_START,
    });
    try {
      const { data } = await getBranchs();
      dispatch({
        type: actions.ORGANIZATION_SUCCESS,
        payload: {
          data: data.results,
          count: data.count,
        },
      });
    } catch (e) {
      dispatch({
        type: actions.ORGANIZATION_ERROR,
        payload: e.toString(),
      });
    }
  };
};
