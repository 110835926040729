import { Pagination } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { birthdayColumns } from "../helpers";
import { getBirthdays } from "../redux/actionCreators/birthdays";
import { authSelector, birthdaySelector } from "../redux/selectors";
import { isStaff } from "../utils/isStaff";

const BirthdaysPage = () => {
  const { data, count, loading, err } = useSelector(birthdaySelector);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const { user } = useSelector(authSelector);
  const _isStaff = isStaff(user.role)

  useEffect(() => {
    dispatch(getBirthdays());
  }, [dispatch]);

  const pageSize = 10;
  return (
    <div>
      <div style={{ height: 650, width: "100%" }}>
        <DataGrid
          rows={data}
          columns={_isStaff ? birthdayColumns.filter((v) => v.field !== "notification") : birthdayColumns}
          rowsPerPageOptions={[pageSize]}
          pageSize={pageSize}
          disableSelectionOnClick
          loading={loading}
          error={err}
          rowCount={count}
          page={page - 1}
        />
      </div>
      <div className="pagination">
        <Pagination
          count={Math.ceil(count / pageSize)}
          page={page}
          onChange={(_, newPage) => {
            setPage(newPage);
          }}
          color="primary"
        />
      </div>
    </div>
  );
};

export default BirthdaysPage;
