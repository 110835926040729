import * as actions from "../actions/positions";

interface IPosition {
  data: any[] | [];
  loading: boolean;
  err: string | null;
  count: number
}

const initialState: IPosition = {
  data: [],
  loading: false,
  err: null,
  count: 0
};

const positionsReducer = (
  state = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case actions.POSITION_GET_START:
      return {
        ...initialState,
        loading: true,
      };
    case actions.POSITION_GET_SUCCESS:
      return {
        loading: false,
        data: action.payload.data,
        count: action.payload.count,
        err: null,
      };
    case actions.POSITION_GET_ERROR:
      return {
        ...state,
        err: action.payload,
      };
    default:
      return state;
  }
};

export default positionsReducer;
