import { CLOSE_PERMISSIONS_MODAL, OPEN_PERMISSIONS_MODAL } from "../actions/permissionsModal";

const initialState: { permissions: string[], show: boolean } = {
  permissions: [],
  show: false
};

const permissionsModalReducer = (
  state = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case OPEN_PERMISSIONS_MODAL:
      return {
        permissions: action.payload,
        show: true
      };
    case CLOSE_PERMISSIONS_MODAL:
      return initialState;
    default:
      return state;
  }
};

export default permissionsModalReducer;
