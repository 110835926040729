import * as actions from "../actions/modal";

const initialState = {
  show: false,
  edit: false,
  row: {},
  type: "persons",
};

const modalReducer = (
  state = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case actions.SHOW:
      return {
        ...initialState,
        show: true,
        type: action.payload.type,
      };
    case actions.SHOW_EDIT:
      return {
        show: true,
        edit: true,
        row: action.payload.row,
        type: action.payload.type,
      };
    case actions.CLOSE:
      return initialState;
    default:
      return state;
  }
};

export default modalReducer;
