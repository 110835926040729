import { Dispatch } from 'redux'
import { getVacations } from '../../api/requests'
import * as actions from '../actions/vacations'


export const fetchVacations = (month: number) => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch({
                type: actions.VACATIONS_START
            })
            const { data } = await getVacations(month)

            dispatch({
                type: actions.VACATIONS_SUCCESS,
                payload: data.results,
                count: data.count,
                id: data.id
            })

        } catch (e: any) {
            dispatch({
                type: actions.VACATIONS_ERROR,
                payload: e.toString()
            })
        }
    }
}