import {
  Button,
  Collapse,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Box } from "@material-ui/core/node_modules/@material-ui/system";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deletePermission, getAllPermissions } from "../api/requests";
import CustomModal from "../components/modal";
import { PermissonDialog } from "../components/modalData";
import { fetchPermissions } from "../redux/actionCreators/permissions";
import { authSelector, permissionsSelector } from "../redux/selectors";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { makeStyles } from "@material-ui/styles";
import { isStaff } from "../utils/isStaff";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

interface IPermissionObject {
  name: string;
  id: number;
}

const PermissionsPage = () => {
  const [permissions, setPermissions] = useState<IPermissionObject[]>([]);
  const [selectedPermission, setSelectedPermission] = useState<null | number>(
    null
  );
  const [isOpen, setIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const { user } = useSelector(authSelector);
  const _isStaff = isStaff(user.role)

  const { data } = useSelector(permissionsSelector);
  const dispatch = useDispatch();
  const handleClose = () => {
    setIsEditing(false);
    setSelectedPermission(null);
    setIsOpen(false);
  };

  const dialogData = PermissonDialog({
    handleClose,
    isOpen,
    id: selectedPermission as number,
    isEditing,
    refetch: () => dispatch(fetchPermissions()),
  });

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await getAllPermissions();
        setPermissions(data.results || []);
      } catch {
        setPermissions([]);
      }
    };
    getData();
    //eslint-disable-next-line
  }, [isOpen]);

  useEffect(() => {
    dispatch(
      fetchPermissions(selectedPermission ? selectedPermission : undefined)
    );
  }, [dispatch, selectedPermission]);

  function Row({ row }: { row: any }) {
    const [open, setOpen] = useState(false);
    const classes = useRowStyles();

    return (
      <>
        <TableRow className={classes.root}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            {row.name}
          </TableCell>
          <TableCell>{row?.employees?.length}</TableCell>
          <TableCell>{row.date_from}</TableCell>
          <TableCell>{row.date_to}</TableCell>
          <TableCell>{row.types?.map((type: any) => type.name)?.join(', ')}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Ф.И.О.</TableCell>
                      <TableCell>Должность</TableCell>
                      <TableCell>Филиал</TableCell>
                      <TableCell>Номер телефона</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.employees?.map((employee: any) => (
                      <TableRow key={employee.id}>
                        <TableCell component="th" scope="row">
                          {employee.full_name}
                        </TableCell>
                        <TableCell>{employee.position_name}</TableCell>
                        <TableCell>{employee.organization_name}</TableCell>
                        <TableCell>{employee.phone_1}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  return (
    <div>
      {isOpen && <CustomModal {...dialogData} />}
      <Select
        placeholder="Допуски"
        style={{ width: 300, marginBottom: 20 }}
        value={selectedPermission || ""}
        onChange={(e) => {
          setSelectedPermission(e.target.value as number);
        }}
      >
        {permissions.map(({ name, id }) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        ))}
      </Select>


    {_isStaff ? null : <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setIsOpen(true);
        }}
        style={{ marginLeft: 25 }}
      >
        Добавить допуск
      </Button>}

      {selectedPermission && !_isStaff && (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            setIsEditing(true);
            setIsOpen(true);
          }}
          style={{ marginLeft: 25 }}
        >
          Изменить
        </Button>
      )}

      {selectedPermission && !_isStaff && (
        <Button
          variant="outlined"
          color="error"
          onClick={() => {
            const isDelete = window.confirm("Удалить?");
            if (isDelete) {
              deletePermission(selectedPermission).then(() => {
                dispatch(fetchPermissions());
                setSelectedPermission(null);
              });
            }
          }}
          style={{ marginLeft: 25 }}
        >
          Удалить
        </Button>
      )}

      {/* <div style={{ height: 650 }}> */}
        <TableContainer component={Paper}>
          <Table style={{ marginBottom: 50 }}>
            <TableHead>
              <TableRow>
                <TableCell>Название</TableCell>
                <TableCell>Количество</TableCell>
                <TableCell>От</TableCell>
                <TableCell>До</TableCell>
                <TableCell>Места</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item, i) => {
                return <Row row={item} key={i} />;
              })}
            </TableBody>
          </Table>
        </TableContainer>
      {/* </div> */}
    </div>
  );
};

export default PermissionsPage;
