import { Dispatch } from "redux";
import { getBirthdays as _getBirthdays } from "./../../api/requests";
import { BIRTHDAY_ERROR, BIRTHDAY_SUCCES } from "../actions/birthday";

export const getBirthdays = () => {
  return async (dispatch: Dispatch) => {
    _getBirthdays()
      .then((res) => {
        dispatch({
          type: BIRTHDAY_SUCCES,
          payload: {
            data: res.data.results,
            count: res.data.count,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: BIRTHDAY_ERROR,
          payload: err.toString(),
        });
      });
  };
};
