import CountUp from "react-countup";
import { Link } from "react-router-dom";

interface ICardProps {
  title: string;
  count: number;
  List?: React.FC;
  link: string
}

const Card = ({ title, count, List, link }: ICardProps) => {
  return (
    <Link to={link} style={{ textDecoration: 'none', color: 'unset' }}>
      <div className="data-card">
        <h3>
          <CountUp end={count} duration={1} />
        </h3>
        <h4>{title}</h4>
        {List && <List />}
      </div>
    </Link>
  );
};

export default Card;
