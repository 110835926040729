import ArchivesPage from "./pages/archives";
import BirthdaysPage from "./pages/birthdays";
import DashboardPage from "./pages/dashboard";
import OrganizationsPage from "./pages/organizations";
import PermissionsPage from "./pages/permissions";
import PersonsPage from "./pages/persons";
import PositionsPage from "./pages/positions";
import VacationsPage from "./pages/vacations";
import VaccinationsPage from "./pages/vaccinations";

export const routes = [
  {
    Component: DashboardPage,
    path: "/dashboard",
    exact: true,
  },
  {
    Component: PersonsPage,
    path: "/persons",
  },
  {
    Component: PositionsPage,
    path: "/positions",
  },
  {
    Component: ArchivesPage,
    path: "/archives",
  },
  {
    Component: BirthdaysPage,
    path: "/birthday",
  },
  {
    Component: OrganizationsPage,
    path: "/organizations",
  },
  {
    Component: VacationsPage,
    path: "/vacations",
  },
  {
    Component: PermissionsPage,
    path: "/permissions",
  },
  {
    Component: VaccinationsPage,
    path: "/vaccinations",
  },
];
