import { Pagination } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../components/DeleteModal";
import { QuickSearchToolbar } from "../components/searchInput";
import { positionsColumns } from "../helpers";
import { useDebounce } from "../hooks/useDebounce";
import { getPositions } from "../redux/actionCreators/positions";
import { authSelector, positionsSelector } from "../redux/selectors";
import { isStaff } from "../utils/isStaff";

const Positions = () => {
  const dispatch = useDispatch();
  const { data, err, loading, count } = useSelector(positionsSelector);
  const onEnd = () => dispatch(getPositions());
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchTerm = useDebounce(searchValue, 500);
  const [page, setPage] = useState(1);
  const { user } = useSelector(authSelector);
  const _isStaff = isStaff(user.role)

  useEffect(() => {
    dispatch(getPositions(debouncedSearchTerm));
  }, [debouncedSearchTerm, dispatch]);

  const pageSize = 10;

  return (
    <div>
      <DeleteModal onEnd={onEnd} />
      <div style={{ height: 650, width: "auto" }}>
        <DataGrid
          rows={data}
          columns={_isStaff ? positionsColumns.filter((v) => v.field !== "edit") : positionsColumns}
          rowsPerPageOptions={[pageSize]}
          pageSize={pageSize}
          disableSelectionOnClick
          loading={loading}
          error={err}
          rowCount={count}
          page={page - 1}
          components={{
            Toolbar: QuickSearchToolbar,
          }}
          componentsProps={{
            toolbar: {
              value: searchValue,
              onChange: (event: any) => {
                setSearchValue(event.target.value);
              },
              clearSearch: () => setSearchValue(""),
            },
          }}
        />
      </div>
      <div className="pagination">
        <Pagination
          count={Math.ceil(count / pageSize)}
          page={page}
          onChange={(_, newPage) => {
            setPage(newPage);
          }}
          color="primary"
        />
      </div>
    </div>
  );
};

export default Positions;
