import { useEffect, useState } from "react";
import { getDashboardInfo } from "../api/requests";
import Card from "../components/card";
import CountUp from "react-countup";

interface IMapper {
  title: string;
  count: number | undefined;
  List?: React.FC;
  link: string;
}

interface IOrganization {
  id: number;
  name: string;
  persons: number;
  fired_persons: number;
}

interface IBirthday {
  id: number;
  full_name: string;
  organization_name: string;
}

interface IInfo {
  data: {
    organization: {
      count: number;
      employee_count: number;
      fired_count: number;
      organizations: IOrganization[];
    };
    position: number;
    vacations: {
      count: number;
      persons: {
        full_name: string;
        id: string;
      }[];
    };
    birthday: {
      count: number;
      persons: IBirthday[];
    };
    vaccination: {
      count: number;
      organizations: {
        name: string
        count: number
      }[]
    }
  } | null;
  loading: boolean;
  err: null | string;
}

const DashboardPage = () => {
  const [info, setInfo] = useState<IInfo>({
    data: null,
    loading: true,
    err: null,
  });

  useEffect(() => {
    getDashboardInfo()
      .then(({ data }: Omit<IInfo, "loading" | "err">) => {
        setInfo({
          data,
          loading: false,
          err: null,
        });
      })
      .catch((err) => {
        setInfo({
          data: null,
          loading: false,
          err: err.toString(),
        });
      });
  }, []);

  const OrganizationList = () => {
    return (
      <>
        <h4>
          Общее число сотрудников:{" "}
          <CountUp
            end={info.data?.organization.employee_count || 0}
            duration={1.5}
          />
        </h4>
        <ul>
          {info.data?.organization.organizations.map((org) => {
            return (
              <li key={org.id}>
                {org.name} ({org.persons})
              </li>
            );
          })}
        </ul>
      </>
    );
  };
  const VaccinationList = () => {
    return (
      <>
        <h4>
          Общее число вакцинированных:{" "}
          <CountUp
            end={info.data?.vaccination.count || 0}
            duration={1.5}
          />
        </h4>
        <ul>
          {info.data?.vaccination.organizations?.map((org) => {
            return (
              <li key={org.name}>
                {org.name} ({org.count})
              </li>
            );
          })}
        </ul>
      </>
    );
  };

  const ArchiveList = () => {
    return (
      <>
        <ul>
          {info.data?.organization.organizations.map((org) => {
            return (
              <li key={org.id}>
                {org.name} ({org.fired_persons})
              </li>
            );
          })}
        </ul>
      </>
    );
  };

  const BirthdayList = () => {
    return (
      <>
        <ul>
          {info.data?.birthday.persons.map((org) => {
            return (
              <li key={org.id}>
                {org.full_name} ({org.organization_name})
              </li>
            );
          })}
        </ul>
      </>
    );
  };

  const VacationsList = () => {
    return (
      <>
        <ul
          style={{ maxHeight: 300, overflowY: "auto" }}
          className="vacations_list"
        >
          {info.data?.vacations.persons.map((person) => {
            return <li key={person.id}>{person.full_name}</li>;
          })}
        </ul>
        <style>
          {`.vacations_list::-webkit-scrollbar {
            width: 4px;
            }
          .vacations_list::-webkit-scrollbar-track {
            box-shadow: inset 0 0 4px rgb(240, 236, 236);
            border-radius: 2px;
            }
          .vacations_list::-webkit-scrollbar-thumb {
            background-color: #747D92;
            border-radius: 2px;
          }`}
        </style>
      </>
    );
  };

  const mapper: IMapper[] = [
    {
      title: "Организации",
      count: info.data?.organization.count,
      List: OrganizationList,
      link: "/organizations",
    },
    {
      title: "Вакцинированные",
      count: info.data?.vaccination.count,
      List: VaccinationList,
      link: "/vaccinations",
    },
    {
      title: "Отпуск",
      count: info.data?.vacations?.count,
      List: VacationsList,
      link: "/vacations",
    },
    {
      title: "День рождения",
      count: info.data?.birthday.count,
      List: BirthdayList,
      link: "/birthday",
    },
    {
      title: "Архив",
      count: info.data?.organization.fired_count,
      List: ArchiveList,
      link: "/archives",
    },
  ];

  return (
    <div>
      <div className="page-contain">
        {mapper.map(({ title, count, List, link }) => {
          return (
            <Card
              key={title}
              title={title}
              count={count || 0}
              link={link}
              List={List}
            />
          );
        })}
      </div>
    </div>
  );
};

export default DashboardPage;
