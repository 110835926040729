import { Dispatch } from "redux";
import { getEmployees as _getEmployees } from "./../../api/requests";
import * as actions from "../actions/employees";

export const getEmployees = (
  search: string = "",
  organization_id: number | null | string = null
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: actions.START_EMPLOYEE,
      });
      const options = { page: 1, pageSize: 500, search, organization_id };
      const { data } = await _getEmployees(options);

      dispatch({
        type: actions.SUCCESS_EMPLOYEE,
        payload: {
          data: data.results,
          count: data.count,
        },
      });
    } catch (error: any) {
      dispatch({
        type: actions.ERROR_EMPLOYEE,
        payload: error.toString(),
      });
    }
  };
};
