import React, { ReactNode } from "react";
import {
  Dialog,
  DialogActions as MuiDialogActions,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogContentText,
  Typography,
  IconButton,
} from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import {
  WithStyles,
  withStyles,
  makeStyles,
  createStyles,
} from "@material-ui/styles";
import CloseIcon from "@material-ui/icons/Close";

export interface ICustomModal {
  dialogProps: {
    onClose: () => void;
    open: boolean;
  };
  dialogContentText?: ReactNode | string;
  dialogTitle: ReactNode | string;
  dialogActions: JSX.Element;
  dialogContent: JSX.Element;
  onSubmit?: (values: any) => void;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const useDialogStyles = makeStyles({
  paper: {
    margin: "15px",
    borderRadius: 8,
  },
});

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const CustomModal = ({
  dialogProps,
  dialogContent,
  dialogActions,
  dialogTitle,
  dialogContentText,
  onSubmit,
}: ICustomModal) => {
  const classes = useDialogStyles();
  return (
    <Dialog maxWidth="md" classes={classes} {...dialogProps}>
      <form onSubmit={onSubmit}>
        <DialogTitle id="form-title" onClose={dialogProps.onClose}>
          {dialogTitle}
        </DialogTitle>
        <DialogContent dividers>
          {dialogContentText && (
            <DialogContentText>{dialogContentText}</DialogContentText>
          )}
          {dialogContent}
        </DialogContent>
        <DialogActions>{dialogActions}</DialogActions>
      </form>
    </Dialog>
  );
};

export default CustomModal;
