import { IEmployee } from '../../types'
import * as actions from '../actions/vacations'


interface IVacations {
    data:  IEmployee[]
    loading: boolean;
    err: string | null;
    count: number;
    id: string | null
  }  

const initialState: IVacations = {
    data: [],
    loading: false,
    err: null,
    count: 0,
    id: null
}


export const vacationsReducer = (state =initialState, action: { type: string; payload: any; count: number, id: string }): IVacations => {
    switch(action.type) {
        case actions.VACATIONS_START:
            return {...initialState, loading: true }
        case actions.VACATIONS_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                err: null,
                count: action.count,
                id: action.id
            }
        case actions.VACATIONS_ERROR:
            return {...initialState, err: action.payload}
        default:
            return state
    }
}


