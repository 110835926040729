import * as actions from "../actions/mobile";

const initialState = false;

const mobileReducer = (
  state = initialState,
  action: { type: string; payload: boolean }
) => {
  switch (action.type) {
    case actions.OPEN_MENU:
      return true;
    case actions.CLOSE_MENU:
      return false;
    default:
      return state;
  }
};

export default mobileReducer;
