import { Pagination } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../components/DeleteModal";
import { organizationColumns } from "../helpers";
import { getOrganizations } from "../redux/actionCreators/organizations";
import {
  authSelector,
  deleteModalSelector,
  modalSelector,
  organizationSelector,
} from "../redux/selectors";
import { isStaff } from "../utils/isStaff";



const OrganizationsPage = () => {
  const { data, count, loading, err } = useSelector(organizationSelector);
  const { show } = useSelector(deleteModalSelector);
  const { edit } = useSelector(modalSelector);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const { user } = useSelector(authSelector);
  const _isStaff = isStaff(user.role)
  const pageSize = 10;

  useEffect(() => {
    if(!show) {
      dispatch(getOrganizations());
    }
  }, [dispatch, edit, show]);

  return (
    <div>
      <DeleteModal />
      <div style={{ height: 650, width: "100%" }}>
        <DataGrid
          rows={data}
          columns={_isStaff ? organizationColumns.filter((v) => v.field !== "edit") : organizationColumns}
          rowsPerPageOptions={[pageSize]}
          pageSize={pageSize}
          disableSelectionOnClick
          loading={loading}
          error={err}
          rowCount={count}
          page={page - 1}
        />
      </div>
      <div className="pagination">
        <Pagination
          count={Math.ceil(count / pageSize)}
          page={page}
          onChange={(_, newPage) => {
            setPage(newPage);
          }}
          color="primary"
        />
      </div>
    </div>
  );
};

export default OrganizationsPage;
