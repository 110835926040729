import { BIRTHDAY_ERROR, BIRTHDAY_SUCCES } from "../actions/birthday";

const initialState = {
  data: [],
  count: 0,
  loading: true,
  err: null,
};

const birthdayReducer = (
  state = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case BIRTHDAY_SUCCES:
      return {
        data: action.payload.data,
        count: action.payload.count,
        loading: false,
        err: null,
      };
    case BIRTHDAY_ERROR:
      return {
        ...initialState,
        err: action.payload,
      };
    default:
      return state;
  }
};

export default birthdayReducer;
