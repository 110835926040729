import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import CakeIcon from "@material-ui/icons/Cake";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import WorkIcon from "@material-ui/icons/Work";
import PeopleIcon from "@material-ui/icons/People";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import { DoneAllOutlined, CalendarTodayOutlined } from "@material-ui/icons";
import LocalHospitalOutlinedIcon from '@material-ui/icons/LocalHospitalOutlined';
import ArchiveIcon from "@material-ui/icons/Archive";
import { ReactComponent as Logo } from "../images/logo.svg";
import Badge from "@material-ui/core/Badge";
import { useLocation, useHistory } from "react-router";
import { Divider } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import {
  archivesSelector,
  birthdaySelector,
  employeesSelector,
  organizationSelector,
  permissionsSelector,
  positionsSelector,
  vacationsSelector,
} from "../redux/selectors";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { RootState } from "../redux/reducers/rootReducer";
import { CLOSE_MENU } from "../redux/actions/mobile";

const Sidebar = ({ user }: { user: any }) => {
  const location = useLocation();
  const history = useHistory();
  const { count: employeeCount } = useSelector(employeesSelector);
  const { count: positionsCount } = useSelector(positionsSelector);
  const { count: birthdaysCount } = useSelector(birthdaySelector);
  const { count: archivesCount } = useSelector(archivesSelector);
  const { count: organizationsCount } = useSelector(organizationSelector);
  const { count: vacationsCount } = useSelector(vacationsSelector)
  const { count: permissionsCount } = useSelector(permissionsSelector)
  const isOpen = useSelector((state: RootState) => state.mobile);
  const dispatch = useDispatch();
  const isTablet = useMediaQuery({
    maxWidth: 1024,
  });

  const sidebarData = [
    {
      title: "Сотрудники",
      Icon: PeopleIcon,
      count: employeeCount,
      link: "/persons",
    },
    {
      title: "Архив",
      Icon: ArchiveIcon,
      count: archivesCount,
      link: "/archives",
    },
    {
      title: "День рождения",
      Icon: CakeIcon,
      count: birthdaysCount,
      link: "/birthday",
    },
    {
      title: "Должности",
      Icon: WorkIcon,
      count: positionsCount,
      link: "/positions",
    },
    {
      title: "Допуски",
      Icon: DoneAllOutlined,
      count: permissionsCount,
      link: "/permissions",
    },
    {
      title: "График отпусков",
      Icon: CalendarTodayOutlined,
      count: vacationsCount,
      link: "/vacations",
    },
    {
      title: "Вакцинация",
      Icon: LocalHospitalOutlinedIcon,
      link: "/vaccinations",
    },
  ];

  return (
    <>
      <div
        className="sidebar"
        style={
          isTablet
            ? {
                transform: isOpen ? undefined : "translateX(-100%)",
              }
            : undefined
        }
      >
        <div className="sidebar-content">
          <div className="sidebar-logo">
            <Link to="/dashboard">
              <Logo
                onClick={() => {
                  dispatch({
                    type: CLOSE_MENU,
                  });
                }}
              />
            </Link>
          </div>
          <Divider style={{ background: "#00b2f6" }} />
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader
                component="div"
                id="nested-list-subheader"
                style={{
                  position: "static",
                  color: "#fff",
                  background: "none",
                }}
              >
                Меню
              </ListSubheader>
            }
          >
            {sidebarData.map(({ link, Icon, title, count }) => {
              return (
                <ListItem
                  key={title}
                  button
                  selected={location.pathname === link}
                  onClick={() => {
                    dispatch({
                      type: CLOSE_MENU,
                    });
                    history.push(link);
                  }}
                >
                  <ListItemIcon>
                    <Icon color="primary" />
                  </ListItemIcon>
                  <Badge max={9999} badgeContent={count} color="primary">
                    <ListItemText primary={title} className="text-500" />
                  </Badge>
                </ListItem>
              );
            })}
            {user?.role === "admin" ? (
              <ListItem
                button
                selected={location.pathname === "/organizations"}
                onClick={() => {
                  dispatch({
                    type: CLOSE_MENU,
                  });
                  history.push("/organizations");
                }}
              >
                <ListItemIcon>
                  <AccountBalanceIcon color="primary" />
                </ListItemIcon>
                <Badge
                  color="primary"
                  max={9999}
                  badgeContent={organizationsCount}
                >
                  <ListItemText primary="Организации" className="text-500" />
                </Badge>
              </ListItem>
            ) : null}
          </List>
        </div>
      </div>
      {isOpen && (
        <div
          className="sidebar-mask"
          onClick={() => {
            dispatch({
              type: CLOSE_MENU,
            });
          }}
        />
      )}
    </>
  );
};

export default Sidebar;
