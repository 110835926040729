import { Dispatch } from 'redux'
import { getPermissions } from '../../api/requests'
import * as actions from '../actions/permissions'


export const fetchPermissions = (id?: number) => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch({
                type: actions.PERMISSIONS_START
            })
            const { data } = await getPermissions(id)

            dispatch({
                type: actions.PERMISSIONS_SUCCESS,
                payload: data.results,
                count: data.count,
                id: data.id
            })

        } catch (e: any) {
            dispatch({
                type: actions.PERMISSIONS_ERROR,
                payload: e.toString()
            })
        }
    }
}