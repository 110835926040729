import { removeTokens } from "./../helpers";
import axios, { AxiosError } from "axios";
import { setToken } from "../helpers";
import store from "../redux/store";

const headers: {
  "Content-Type": string;
  Accept: string;
  Authorization?: string | null;
} = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const token = localStorage.getItem("token") || null;

if (token) {
  headers.Authorization = "Bearer " + token;
}

const config = {
  baseURL: "https://b-day.uztelecom.uz/api/",
  // baseURL: "http://admin1570.pythonanywhere.com/api",
  headers,
};

const API = axios.create(config);

export const nonAuthApi = axios.create(config);

const refreshToken = (refresh: string) => {
  return API.post("token/refresh/", { refresh });
};

interface _AxiosError extends AxiosError {
  config: {
    _retry: boolean;
    url: string;
    headers: any;
  };
}

API.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error: _AxiosError) => {
    const originalRequest = error.config;

    const urls = ["token/", "token/refresh/"];

    if (originalRequest?.url === "token/refresh/") {
      store.dispatch({
        type: "LOGOUT",
        payload: {},
      });
      removeTokens();
    }

    if (
      (error.response?.status === 403 || error?.response?.status === 401) &&
      !originalRequest._retry &&
      !urls.includes(originalRequest?.url)
    ) {
      originalRequest._retry = true;
      try {
        const _refresh = localStorage.getItem("refresh") || "";
        const tokenResponse = await refreshToken(_refresh);
        const { access } = tokenResponse.data;
        setToken(API, access);
        API.defaults.headers["Authorization"] = "Bearer " + access;
        originalRequest.headers["Authorization"] = "Bearer " + access;
        return API(originalRequest);
      } catch (err: any) {
        throw new Error(err.toString());
      }
    }

    return Promise.reject(error);
  }
);

export default API;
