import { IBranch, IEmployee } from "./../types";
import API from "./";

export const login = (values: { email: string; password: string }) => {
  return API.post("token/", values);
};

export const getMe = () => {
  return API.get("account/me");
};

export const getEmployees = ({
  page,
  pageSize,
  search,
  organization_id,
}: {
  page: number;
  pageSize: number;
  search: string;
  organization_id: number | null | string;
}) => {
  const params: any = {
    limit: pageSize,
  };

  if (page > 1) {
    params.offset = (page - 1) * pageSize;
  }

  if (search.length) {
    params.search = search;
  }
  if (organization_id && organization_id !== "all") {
    params.organization_id = organization_id;
  }

  return API.get("staff/employee/", {
    params,
  });
};

export const editEmployee = (id: Number, values: any) => {
  return API.patch(`staff/employee/${id}/`, values);
};

export const deleteEmployee = (id: number) => {
  return API.delete(`staff/employee/${id}/`);
};

export const addEmployee = (values: IEmployee) => {
  return API.post("staff/employee/", values);
};

export const getBranchs = () => {
  return API.get("staff/organization/", {
    params: {
      limit: 500,
    },
  });
};

export const addBranch = (values: IBranch) => {
  return API.post("staff/organization/", values);
};

export const deleteBranch = (id: number) => {
  return API.delete(`staff/organization/${id}/`);
};

export const editBranch = (id: number, values: any) => {
  return API.patch(`staff/organization/${id}/`, values);
};

export const getPositions = ({ search }: { search: string }) => {
  const params: { limit: number; search?: string } = {
    limit: 500,
  };
  if (search?.length) {
    params.search = search;
  }

  return API.get("staff/position/", {
    params,
  });
};

export const addPosition = (value: { name: string }) => {
  return API.post("staff/position/", value);
};

export const editPosition = (id: number, values: any) => {
  return API.patch(`staff/position/${id}/`, values);
};

export const deletePosition = (id: number) => {
  return API.delete(`staff/position/${id}/`);
};

export const getArchives = ({
  search,
  organization_id,
}: {
  search: string;
  organization_id: number | null | string;
}) => {
  const params: {
    limit: number;
    search?: string;
    organization_id?: number | null | string;
  } = {
    limit: 500,
  };

  if (search?.length) {
    params.search = search;
  }

  if (organization_id && organization_id !== "all") {
    params.organization_id = organization_id;
  }

  return API.get("staff/archive/", {
    params,
  });
};

export const getBirthdays = () => {
  return API.get("staff/birthday/", {
    params: {
      limit: 500,
    },
  });
};

export const sendAllNotifications = () => {
  return API.post("staff/send_all_notification/", "");
};

export const sendNotificationToUser = (id: number) => {
  return API.get(`staff/send_notification/${id}/`);
};

export const getExportFile = (organization_id: any, isArchive: boolean) => {
  const params: any = {
    isArchive: isArchive ? "True" : "False",
  };

  if (organization_id && organization_id !== "all") {
    params.organization_id = organization_id;
  }

  return API.get("staff/file/", {
    responseType: "arraybuffer",
    params,
  });
};

export const getDashboardInfo = () => {
  return API.get("staff/dashboard/");
};

export const getVacations = (month: number) => {
  return API.get("staff/vacation/", {
    params: {
      month,
      limit: 500
    },
  });
};

export const updateVacations = (id: string, employees: string[]) => {
  return API.patch(`staff/vacation/${id}/`, { employees });
};

export const addVacations = (employees: string[], vacation_date: string) => {
  return API.post(`staff/vacation/`, { employees, vacation_date });
};

const permissionsApiPath = "/staff/permission/";

export const getPermissions = (id?: number) => {
  return API.get(permissionsApiPath, {
    params: {
      id,
    },
  });
};

export const getAllPermissions = () => {
  return API.get("/staff/permission-names/", {
    params: {
      limit: 500,
    },
  });
};

export const addPermission = (
  employees: string[],
  date_to: string,
  date_from: string,
  name: string,
  types: number[]
) => {
  return API.post(permissionsApiPath, { employees, date_to, date_from, name, types });
};

export const getPermission = (id: number) => {
  return API.get(`${permissionsApiPath}/${id}/`, {
    params: {
      limit: 500
    }
  });
};

export const deletePermission = (id: number) => {
  return API.delete(`${permissionsApiPath}/${id}/`);
};

export const updatePermission = (
  employees: string[],
  date_to: string,
  date_from: string,
  name: string,
  id: number,
  types: number[]
) => {
  return API.patch(`${permissionsApiPath}/${id}/`, {
    employees,
    date_to,
    date_from,
    name,
    types
  });
};

export const getPermissionTypes = () => {
  return API.get("/staff/permission-types/", {
    params: {
      limit: 500
    }
  })
}


const API_PATH_VACCINATION = '/staff/vaccination/'

interface IVaccinationParmas {
  type?: string
  org_type?: string
}

export const getAllVaccinations = ({ org_type, type }: IVaccinationParmas) => {
  return API.get(API_PATH_VACCINATION, {
    params: {
      limit: 500
    }
  })
}

export const getVaccineTypes = () => {
  return API.get("/staff/vaccine-types/", {
    params: {
      limit: 500
    }
  })
}

export const addVaccination = (data: any) => {
  return API.post(`${API_PATH_VACCINATION}`, data)
}


export const updateVaccination = (id: number, data: any) => {
  return API.patch(`${API_PATH_VACCINATION}${id}/`, data)
}

export const deleteVaccination = (id: number) => {
  return API.delete(`${API_PATH_VACCINATION}${id}/`)
}