import { Pagination } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEmployees } from "../redux/actionCreators/employees";
import { authSelector, employeesSelector } from "../redux/selectors";
import { employeeColumns } from "../helpers";
import { useState } from "react";
import DeleteModal from "./DeleteModal";
import { useCallback } from "react";
import { QuickSearchToolbar } from "./searchInput";
import { useDebounce } from "../hooks/useDebounce";
import PermissionsModal from "./PermissionsModal";
import { isStaff } from "../utils/isStaff";

const EmployeeTable = () => {
  const dispatch = useDispatch();
  const { data, loading, err, count } = useSelector(employeesSelector);
  const { loggedIn, user } = useSelector(authSelector);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchTerm = useDebounce(searchValue, 500);
  const _isStaff = isStaff(user.role)

  const getData = useCallback(
    () => dispatch(getEmployees()),
    // eslint-disable-next-line
    [dispatch]
  );

  useEffect(() => {
    if (loggedIn) {
      dispatch(getEmployees(debouncedSearchTerm));
    }
  }, [debouncedSearchTerm, dispatch, loggedIn, getData]);

  const pageSize = 10;


  return (
    <div>
      <DeleteModal onEnd={getData} />
      <PermissionsModal />
      <div style={{ height: 650, width: "100%" }}>
        <DataGrid
          rows={data}
          columns={_isStaff ? employeeColumns.filter((v) => v.field !== "edit") : employeeColumns}
          components={{
            Toolbar: QuickSearchToolbar,
          }}
          componentsProps={{
            toolbar: {
              value: searchValue,
              onChange: (event: any) => setSearchValue(event.target.value),
              clearSearch: () => setSearchValue(""),
              isArchive: false,
            },
          }}
          rowsPerPageOptions={[pageSize]}
          pageSize={pageSize}
          disableSelectionOnClick
          loading={loading}
          error={err}
          rowCount={count}
          page={page - 1}
        />
      </div>
      <div className="pagination">
        <Pagination
          count={Math.ceil(count / pageSize)}
          page={page}
          onChange={(_, newPage) => {
            setPage(newPage);
          }}
          color="primary"
        />
      </div>
    </div>
  );
};

export default EmployeeTable;
