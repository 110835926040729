import { GridColDef } from "@material-ui/data-grid";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import store from "./redux/store";
import { OPEN_DELETE_MODAL } from "./redux/actions/deleteModal";
import { Button, Typography } from "@material-ui/core";
import { sendNotificationToUser } from "./api/requests";
import { DoneAllOutlined } from "@material-ui/icons";
import { OPEN_PERMISSIONS_MODAL } from "./redux/actions/permissionsModal";
import { SHOW_MODAL } from "./redux/actions/vaccinationsModal";

export const setToken = (API: any, token: string, refresh?: string) => {
  localStorage.setItem("token", token);
  if (refresh) {
    localStorage.setItem("refresh", refresh);
  }
  if (API) {
    API.defaults.headers["Authorization"] = `Bearer ${token}`;
  }
};

export const removeTokens = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("refresh");
};

export const employeeColumns: GridColDef[] = [
  {
    field: "full_name",
    headerName: "Ф.И.О.",
    width: 280,
  },
  {
    field: "date_of_birth",
    headerName: "День рождения",
    width: 150,
  },
  {
    headerName: "Допуски",
    field: "permissions",
    width: 110,
    filterable: false,
    sortable: false,
    renderCell: (params) => {
      return params.row.permissions?.length ? (<>
        <DoneAllOutlined color="primary" style={{ cursor: "pointer" }} onClick={() => {
          store.dispatch({
            type: OPEN_PERMISSIONS_MODAL,
            payload: params.row.permissions?.map((permission: any) => permission.name),
          });
        }} /></>) : null
    }
  },
  {
    field: "phone_1",
    headerName: "Номер телефона",
    width: 130,
  },
  {
    field: "vaccination",
    headerName: "Вакцинация",
    width: 180,
    renderCell: (params) => {
      const vaccinations = params.row.vaccination
      return vaccinations?.map((item: any, index: number) => {
        return `${item.count} - ${item.name} ${vaccinations.length - 1 === index ? '' : ','}`
      })
    }
  },
  {
    field: "organization_name",
    headerName: "Организация",
    width: 180,
  },
  {
    field: "position_name",
    headerName: "Должность",
    width: 180,
  },
  {
    field: "email",
    headerName: "Почта",
    width: 150,
  },
  {
    field: "gender_in_russian",
    headerName: "Пол",
    width: 90,
  },
  {
    field: "edit",
    headerName: "edit",
    width: 80,
    renderCell: (params) => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <DeleteIcon
            color="secondary"
            style={{ cursor: "pointer" }}
            onClick={() => {
              store.dispatch({
                type: OPEN_DELETE_MODAL,
                payload: {
                  title: "Сотрудника",
                  id: params.row?.id,
                  deletingObj: params.row?.full_name,
                  type: "persons",
                },
              });
            }}
          />
          <EditIcon
            color="primary"
            onClick={() => {
              store.dispatch({
                type: "SHOW_EDIT",
                payload: {
                  type: "persons",
                  row: params.row,
                },
              });
            }}
            style={{ cursor: "pointer" }}
          />
        </div>
      );
    },
  },
];


export const vaccinationColumns: GridColDef[] = [
  {
    field: "full_name",
    headerName: "Ф.И.О.",
    width: 280,
  },
  {
    field: "organization_name",
    headerName: "Организация",
    width: 300,
  },
  {
    field: "vaccinated_dates",
    headerName: "Полученные даты",
    width: 800,
    renderCell: (params) => {
      return params.row.vaccinated_dates?.map((item: any,_: number, arr:any) => {
          return (<div key={item.id} style={{ display: 'flex', marginRight: 10 }}>
            <Typography onClick={() => {
              store.dispatch({
                type: SHOW_MODAL,
                payload: {
                  id: item.id,
                  issuedDate: item.issued_date,
                  employeeName: params.row.full_name,
                  vaccineTypeId: item.vaccine_type_id,
                  employeeId: params.row.id,
                  isEditing: true
                }
              })
            }} style={{ cursor: 'pointer', textDecoration: "underline" }}>
              {item.vaccine_type} {item.issued_date}{_ === arr.length - 1 ? '' : ',' }
            </Typography>
          </div>)
      })
    }
  },
];

export const timeOptions = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
};

export const vacationsColumns: GridColDef[] = [
  {
    field: "vacation_date",
    headerName: "Отпуск",
    width: 280,
    renderCell: (params) => {
      if (typeof params.value === "string") {
        // @ts-ignore
        return new Date(params.value || "10-10-2020").toLocaleDateString("ru",timeOptions);
      }
      return params.value;
    },
  },
  {
    field: "full_name",
    headerName: "Ф.И.О.",
    width: 280,
  },
  {
    field: "phone_1",
    headerName: "Номер телефона",
    width: 210,
  },
  {
    field: "organization_name",
    headerName: "Организация",
    width: 180,
  },
  {
    field: "gender_in_russian",
    headerName: "Пол",
    width: 120,
  },
  {
    field: "edit",
    headerName: "edit",
    width: 80,
    renderCell: (params) => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <EditIcon
            color="primary"
            onClick={() => {
              store.dispatch({
                type: "SHOW_EDIT",
                payload: {
                  type: "persons",
                  row: params.row,
                },
              });
            }}
            style={{ cursor: "pointer" }}
          />
        </div>
      );
    },
  },
];

export const permissionsColumns: GridColDef[] = [
  {
    field: "employees",
    headerName: "Допуски",
    width: 1000,
    renderCell: (params) => {
      //@ts-ignore
      return params?.value?.map((item: any) => item.first_name + " " + item.last_name).join(", ");
    },
  },
  {
    field: "name",
    headerName: "Название",
    width: 200,
  },
  {
    field: "date_from",
    headerName: "Начало",
    width: 150,
  },
  {
    field: "date_to",
    headerName: "Конец",
    width: 150,
  },
  // {
  //   field: "edit",
  //   headerName: "edit",
  //   width: 80,
  //   renderCell: (params) => {
  //     return (
  //       <div
  //         style={{
  //           display: "flex",
  //           justifyContent: "center",
  //           width: "100%",
  //         }}
  //       >
  //         <DeleteIcon
  //           color="secondary"
  //           style={{ cursor: "pointer" }}
  //           onClick={() => {
  //             store.dispatch({
  //               type: OPEN_DELETE_MODAL,
  //               payload: {
  //                 title: "Сотрудника",
  //                 id: params.row?.id,
  //                 deletingObj: params.row?.full_name,
  //                 type: "persons",
  //               },
  //             });
  //           }}
  //         />
  //       </div>
  //     );
  //   },
  // },
];
export const birthdayColumns: GridColDef[] = [
  {
    field: "full_name",
    headerName: "Ф.И.О.",
    width: 280,
  },
  {
    field: "date_of_birth",
    headerName: "День рождения",
    width: 150,
  },
  {
    field: "phone_1",
    headerName: "Номер телефона",
    width: 210,
  },
  {
    field: "phone_2",
    headerName: "Доп. номер телефона",
    width: 160,
  },
  {
    field: "organization_name",
    headerName: "Организация",
    width: 180,
  },
  {
    field: "position_name",
    headerName: "Должность",
    width: 180,
  },
  {
    field: "email",
    headerName: "Почта",
    width: 170,
  },
  {
    field: "gender_in_russian",
    headerName: "Пол",
    width: 120,
  },
  {
    field: "notification",
    headerName: "Отправить",
    width: 150,
    renderCell: (params) => {
      return (
        <Button
          variant="contained"
          onClick={() => {
            sendNotificationToUser(params.row.id).then((res) => {
              // console.log(res.data);
            });
          }}
        >
          Отправить
        </Button>
      );
    },
  },
];
export const positionsColumns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    width: 200,
  },
  {
    field: "name",
    headerName: "Название",
    width: 300,
  },
  {
    field: "persons",
    headerName: "Количество",
    width: 210,
  },
  {
    field: "edit",
    headerName: "Действия",
    width: 140,
    renderCell: (params) => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
          }}
        >
          <DeleteIcon
            color="secondary"
            style={{ cursor: "pointer" }}
            onClick={() => {
              store.dispatch({
                type: OPEN_DELETE_MODAL,
                payload: {
                  title: "должности",
                  id: params.row?.id,
                  deletingObj: params.row?.name,
                  type: "positions",
                },
              });
            }}
          />
          <EditIcon
            color="primary"
            onClick={() => {
              store.dispatch({
                type: "SHOW_EDIT",
                payload: {
                  type: "position",
                  row: params.row,
                },
              });
            }}
            style={{ cursor: "pointer" }}
          />
        </div>
      );
    },
  },
];

export const archivesColumns: GridColDef[] = [
  {
    field: "full_name",
    headerName: "Ф.И.О.",
    width: 280,
  },
  {
    field: "date_of_birth",
    headerName: "День рождения",
    width: 150,
  },
  {
    field: "phone_1",
    headerName: "Номер телефона",
    width: 210,
  },
  {
    field: "phone_2",
    headerName: "Доп. номер телефона",
    width: 160,
  },
  {
    field: "organization_name",
    headerName: "Организация",
    width: 180,
  },
  {
    field: "position_name",
    headerName: "Должность",
    width: 180,
  },
  {
    field: "email",
    headerName: "Почта",
    width: 170,
  },
  {
    field: "gender_in_russian",
    headerName: "Пол",
    width: 120,
  },
  {
    field: "edit",
    headerName: "edit",
    width: 80,
    renderCell: (params) => {
      return (
        <EditIcon
          color="primary"
          onClick={() => {
            store.dispatch({
              type: "SHOW_EDIT",
              payload: {
                type: "persons",
                row: params.row,
              },
            });
          }}
          style={{ cursor: "pointer" }}
        />
      );
    },
  },
];

// address: null
// chat_id: "-570645308"
// description: ""
// email: null
// id: 1
// name: "Telekom"
// persons: 7
// phone: null
// website: null

export const organizationColumns: GridColDef[] = [
  {
    field: "name",
    headerName: "Название",
    width: 200,
  },
  {
    field: "address",
    headerName: "Адрес",
    width: 200,
  },
  {
    field: "description",
    headerName: "Описание",
    width: 200,
  },
  {
    field: "email",
    headerName: "Почта",
    width: 150,
  },
  {
    field: "website",
    headerName: "Сайт",
    width: 150,
  },
  {
    field: "persons",
    headerName: "Сотрудники",
    width: 120,
  },
  {
    field: "phone",
    headerName: "Телефон",
    width: 180,
  },
  {
    field: "chat_id",
    headerName: "Chat ID",
    width: 140,
  },
  {
    field: "edit",
    headerName: "edit",
    width: 80,
    renderCell: (params) => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <DeleteIcon
            color="secondary"
            style={{ cursor: "pointer" }}
            onClick={() => {
              store.dispatch({
                type: OPEN_DELETE_MODAL,
                payload: {
                  title: "Организации",
                  id: params.row?.id,
                  deletingObj: params.row?.name,
                  type: "branch",
                },
              });
            }}
          />
          <EditIcon
            color="primary"
            onClick={() => {
              store.dispatch({
                type: "SHOW_EDIT",
                payload: {
                  type: "branch",
                  row: params.row,
                },
              });
            }}
            style={{ cursor: "pointer" }}
          />
        </div>
      );
    },
  },
];

export const setFieldErrors = (err: any, setError: any) => {
  const errors = err?.response?.data;
  const fields = Object.keys(errors || {});
  if (fields.length) {
    fields.forEach((field) => {
      setError(field, {
        message: errors[field],
      });
    });
  }
};

export const months = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
];
