import {
  Dialog,
  // DialogActions as MuiDialogActions,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  // DialogContentText,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { CLOSE_PERMISSIONS_MODAL } from "../redux/actions/permissionsModal";
import { permissionsModalSelector } from "../redux/selectors";

interface IProps {}

const PermissionsModal: React.FC<IProps> = () => {
  const { permissions, show } = useSelector(permissionsModalSelector);
  const dispatch = useDispatch();
  return (
    <Dialog
      open={show}
      onClose={() => {
        dispatch({
          type: CLOSE_PERMISSIONS_MODAL,
        });
      }}
    >
      <DialogTitle>Допуски сотрудника</DialogTitle>
      <DialogContent>
        {
          <List>
            {permissions.map((name: any) => {
              return <ListItem key={name}>{name}</ListItem>;
            })}
          </List>
        }
      </DialogContent>
    </Dialog>
  );
};

export default PermissionsModal;
