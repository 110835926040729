import {
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { DatePicker, LocalizationProvider } from "@material-ui/lab";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  addVaccination,
  deleteVaccination,
  getVaccineTypes,
  updateVaccination,
} from "../api/requests";
import {
  employeesSelector,
  vaccinationsModalSelector,
} from "../redux/selectors";
import { getDateParsedValues } from "../utils/getDateParsedValues";
import ReactHookFormSelect from "./ReactHookFormSelect";
import Select from "react-select";

interface IProps {
  handleClose: () => void;
}

const VaccinationsModal = ({ handleClose }: IProps) => {
  const {
    reset,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const { issuedDate, id, employeeName, vaccineTypeId, employeeId, isEditing } =
    useSelector(vaccinationsModalSelector);
  const employee = watch("employee");
  const { data } = useSelector(employeesSelector);
  const [loading, setLoading] = useState(false);

  const onSubmit = handleSubmit((values: any) => {
    setLoading(true);
    const issued_date = getDateParsedValues(values.issued_date);
    if (isEditing) {
      updateVaccination(id, {
        issued_date: issued_date,
        type: values.type,
        employee: employeeId,
      }).finally(() => {
        handleClose();
        setLoading(false);
      });
    } else {
      addVaccination({
        type: values.type,
        employee: values.employee.value,
        issued_date,
      }).finally(() => {
        handleClose();
        setLoading(false);
      });
    }
  });

  const dialogTitle = "Вакцинация";

  useEffect(() => {
    reset({
      issued_date: issuedDate,
      type: vaccineTypeId,
    });
  }, [issuedDate, vaccineTypeId, reset]);

  const dialogActions = (
    <>
      <Button
        // onClick={handleClose}
        color="primary"
        variant="outlined"
        type="submit"
        startIcon={loading && <CircularProgress color="inherit" size={20} />}
        size="large"
      >
        Сохранить
      </Button>
      {isEditing && (
        <Button
          onClick={() => {
            setLoading(true);
            deleteVaccination(id).finally(() => {
              setLoading(false);
              handleClose();
            });
          }}
          color="error"
          variant="outlined"
          startIcon={loading && <CircularProgress color="inherit" size={20} />}
          size="large"
        >
          Удалить
        </Button>
      )}
      <Button
        size="large"
        onClick={handleClose}
        color="secondary"
        variant="outlined"
      >
        Отмена
      </Button>
    </>
  );

  const dialogProps = {
    open: true,
    onClose: () => {
      handleClose();
      reset({});
    },
  };
  const [vaccineTypes, setVaccineTypes] = useState<any[]>([]);

  useEffect(() => {
    getVaccineTypes().then((res) => {
      setVaccineTypes(res.data.results);
    });
  }, []);

  const dialogContent = (
    <div style={{ width: 500, height: 600 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">{employeeName}</Typography>
        </Grid>
        {!isEditing && (
          <Grid item xs={12}>
            <Select
              className="basic-multi-select"
              classNamePrefix="select"
              isMulti={false}
              options={data.map(({ id, full_name }) => ({
                label: full_name,
                value: id,
              }))}
              placeholder="Сотрудник"
              value={employee}
              onChange={(newValue) => {
                //@ts-ignore
                setValue("employee", newValue);
              }}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
              control={control}
              defaultValue={null}
              rules={{
                required: {
                  message: "Пожалуйста выберите дату.",
                  value: true,
                },
              }}
              name="issued_date"
              render={({ field: { ref, ...rest }, formState: { errors } }) => {
                return (
                  <DatePicker
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        helperText={errors?.issued_date?.message}
                        error={errors.issued_date ? true : false}
                        className="w-100p"
                        margin="normal"
                        style={{ zIndex: 0 }}
                      />
                    )}
                    inputFormat="dd-MM-yyyy"
                    label="Дата получения"
                    className="w-100p"
                    mask="__-__-____"
                    {...rest}
                  />
                );
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12}>
          <ReactHookFormSelect
            name="type"
            control={control}
            errors={errors}
            message="Пожалуйста выберите вакцину"
            defaultValue={""}
            label="Вакцина"
            variant="outlined"
            style={{ zIndex: 0 }}
            className="formField"
            error={Boolean(errors?.vaccine)}
          >
            {vaccineTypes.map(
              (vaccine: { id: string | number; name: string }) => {
                return (
                  <MenuItem key={vaccine.id} value={vaccine.id}>
                    {vaccine.name}
                  </MenuItem>
                );
              }
            )}
          </ReactHookFormSelect>
        </Grid>
      </Grid>
    </div>
  );

  return {
    dialogContent,
    dialogProps,
    dialogTitle,
    dialogActions,
    onSubmit,
  };
};

export default VaccinationsModal;
