import { Button } from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ReactComponent as MenuIcon } from "../images/menu.svg";
import { removeTokens } from "../helpers";
import { OPEN_MENU } from "../redux/actions/mobile";

const Navbar = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <div className="navbar">
      <div className="navbar-content">
        <div className="navbar-menu">
          <MenuIcon
            width={30}
            onClick={() => {
              dispatch({
                type: OPEN_MENU,
              });
            }}
            height={30}
            fill="#fff"
          />
        </div>
        <Button
          startIcon={<ExitToAppIcon fontSize="large" />}
          variant="contained"
          color="primary"
          onClick={() => {
            history.push("/");
            dispatch({
              type: "LOGOUT",
            });
            removeTokens();
          }}
        >
          Выход
        </Button>
      </div>
    </div>
  );
};

export default Navbar;
