import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { months, vacationsColumns } from "../helpers";
import { fetchVacations } from "../redux/actionCreators/vacations";
import { authSelector, modalSelector, vacationsSelector } from "../redux/selectors";
import { VacationsDialog } from "../components/modalData";
import CustomModal from "../components/modal";
import { isStaff } from "../utils/isStaff";

const VacationsPage = () => {
  const dispatch = useDispatch();
  const d = new Date();
  const { data, loading, err, count, id } = useSelector(vacationsSelector);
  const {  show,  } = useSelector(modalSelector);
  const [month, setMonth] = useState(d.getMonth() + 1);
  // const pageSize = 10;
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useSelector(authSelector);
  const _isStaff = isStaff(user.role)


  useEffect(() => {
    dispatch(fetchVacations(month));
  }, [dispatch, month, show]);

 

  const handleClose = () => {
    setIsOpen(false);
  };


  const dialogData = VacationsDialog({ handleClose, isOpen, selectedMonth: month, id: id || '', refetch: () => dispatch(fetchVacations(month)) });

  return (
    <div>
      {isOpen && <CustomModal {...dialogData} />}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: 25,
          gap: 20,
        }}
      >
        <FormControl variant="outlined" style={{ width: 250 }}>
          <InputLabel id="month">Месяц</InputLabel>
          <Select
            labelId="month"
            id="demo-simple-select-outlined"
            value={month}
            onChange={(value) => {
              setMonth(Number(value.target.value));
            }}
            label="Месяц"
          >
            {months.map((name, i) => (
              <MenuItem key={i} value={i + 1}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {!_isStaff &&  <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          Изменить
        </Button>}
      </div>
      <div style={{ height: 650, width: "auto" }}>
        <DataGrid
          rows={data}
          // pageSize={pageSize}
          columns={_isStaff ? vacationsColumns.filter((v) => v.field !== "edit") : vacationsColumns}
          loading={loading}
          error={err}
          rowCount={count}
          // rowsPerPageOptions={[pageSize]}
        />
      </div>
    </div>
  );
};

export default VacationsPage;
