import { Pagination } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../components/DeleteModal";
import { QuickSearchToolbar } from "../components/searchInput";
import { employeeColumns } from "../helpers";
import { useDebounce } from "../hooks/useDebounce";
import { getArchives } from "../redux/actionCreators/archives";
import { archivesSelector, authSelector } from "../redux/selectors";
import { isStaff } from "../utils/isStaff";

const Archives = () => {
  const { data, count, loading, err } = useSelector(archivesSelector);
  const [searchVal, setSearchVal] = useState("");
  const debouncedSearchTerm = useDebounce(searchVal, 500);
  const dispatch = useDispatch();
  const pageSize = 10;
  const [page, setPage] = useState(1);
  const { user } = useSelector(authSelector);
  const _isStaff = isStaff(user.role)

  useEffect(() => {
    dispatch(getArchives(debouncedSearchTerm));
  }, [dispatch, debouncedSearchTerm]);

  return (
    <div>
      <DeleteModal onEnd={() => dispatch(getArchives())} />
      <div style={{ height: 650, width: "100%" }}>
        <DataGrid
          rows={data}
          components={{
            Toolbar: QuickSearchToolbar,
          }}
          componentsProps={{
            toolbar: {
              value: searchVal,
              onChange: (e: any) => setSearchVal(e.target.value),
              clearSearch: () => setSearchVal(""),
              isArchive: true,
            },
          }}
          columns={_isStaff ? employeeColumns.filter((v) => v.field !== "edit") : employeeColumns}
          rowsPerPageOptions={[pageSize]}
          pageSize={pageSize}
          disableSelectionOnClick
          loading={loading}
          error={err}
          rowCount={count}
          page={page - 1}
        />
      </div>
      <div className="pagination">
        <Pagination
          count={Math.ceil(count / pageSize)}
          page={page}
          onChange={(_, newPage) => {
            setPage(newPage);
          }}
          color="primary"
        />
      </div>
    </div>
  );
};

export default Archives;
