import * as actions from "../actions/organizations";

const initialState = {
  count: 0,
  data: [],
  loading: true,
  err: null,
};

const organizationReducer = (
  state = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case actions.ORGANIZATION_START:
      return {
        ...initialState,
        loading: true,
      };
    case actions.ORGANIZATION_SUCCESS:
      return {
        data: action.payload.data,
        count: action.payload.count,
        loading: false,
        err: null,
      };
    case actions.ORGANIZATION_ERROR:
      return {
        ...state,
        loading: false,
        err: action.payload,
      };
    default:
      return state;
  }
};

export default organizationReducer;
