import { CircularProgress } from "@material-ui/core";
import { ReactComponent as Logo } from "../images/logo.svg";

const Loader = () => {
  return (
    <div className="loader">
      <CircularProgress />
      <div
        style={{
          marginTop: 15,
        }}
      >
        <Logo />
      </div>
    </div>
  );
};

export default Loader;
