import { Snackbar, Alert as MuiAlert, AlertProps } from "@material-ui/core";

interface IAlert {
  type: "success" | "error";
  text?: string;
  open: boolean;
  setOpen: (arg: boolean) => void;
}

const Alert = ({ type, text, setOpen, open }: IAlert) => {
  const AlertContent = (props: AlertProps) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  const closeAlert = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      className="snackbar"
      key={text}
      onClose={closeAlert}
      message={
        <AlertContent onClose={closeAlert} severity={type}>
          {text}
        </AlertContent>
      }
    />
  );
};

export default Alert;
