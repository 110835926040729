import * as actions from "../actions/auth";

interface IAuth {
  loggedIn: boolean;
  user: any;
  err: string | null;
  loading: boolean;
}

const initialState: IAuth = {
  loggedIn: false,
  user: {},
  err: null,
  loading: false,
};

const authReducer = (
  state: IAuth = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case actions.LOGIN_START:
      return {
        ...state,
        loading: true,
      };
    case actions.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        loading: false,
        err: null,
        user: action.payload,
      };
    case actions.LOGIN_ERROR:
      return {
        ...state,
        err: action.payload,
        loading: false,
      };
    case actions.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default authReducer;
