import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getArchives } from "../redux/actionCreators/archives";
import { getBirthdays } from "../redux/actionCreators/birthdays";
import { getEmployees } from "../redux/actionCreators/employees";
import { getOrganizations } from "../redux/actionCreators/organizations";
import { fetchPermissions } from "../redux/actionCreators/permissions";
import { getPositions } from "../redux/actionCreators/positions";
import { fetchVacations } from "../redux/actionCreators/vacations";
import { authSelector } from "../redux/selectors";
import ButtonList from "./buttonList";
import Navbar from "./navbar";
import Sidebar from "./sidebar";
import "./styles.scss";

const Layout = ({ children }: { children: any }) => {
  const { loggedIn, user } = useSelector(authSelector);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (loggedIn) {
      dispatch(getPositions());
      dispatch(getBirthdays());
      dispatch(getArchives());
      dispatch(getEmployees());
      dispatch(fetchPermissions());
      dispatch(fetchVacations(new Date().getMonth() + 1));
      if (user?.role === "admin") {
        dispatch(getOrganizations());
      }
    } else {
      history.push("/");
    }
    // eslint-disable-next-line
  }, [loggedIn, dispatch]);

  return (
    <div className="layout">
      <Sidebar user={user} />
      <Navbar />
      <div className="content">
        <ButtonList />
        {children}
      </div>
    </div>
  );
};

export default Layout;
