import * as actions from '../actions/vaccinationsModal'


const initialState = {
    show: false,
    id: null,
    employeeName: '',
    issuedDate: null,
    vaccineTypeId: null,
    employeeId: null,
    isEditing: false
}


export const vaccinationsModalReducer = (state=initialState, action: { type: string, payload: any }) => {
    switch(action.type) {
        case actions.SHOW_MODAL:
            return {
                show: true,
                id: action.payload.id,
                employeeName: action.payload.employeeName,
                issuedDate: action.payload.issuedDate,
                vaccineTypeId: action.payload.vaccineTypeId,
                employeeId: action.payload.employeeId,
                isEditing: action.payload.isEditing
            }
        default:
            return initialState
    }
}