import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./theme";
import LoginPage from "./pages/login";
import { useDispatch, useSelector } from "react-redux";
import { checkUser } from "./redux/actionCreators/auth";
import { useEffect } from "react";
import { routes } from "./routes";
import { authSelector } from "./redux/selectors";
import AuthMiddleware from "./AuthMiddleware";
import Loader from "./components/Loader";
import Layout from "./components/layout";
import { Typography } from "@material-ui/core";
import "./components/styles.scss";

const App = () => {
  const dispatch = useDispatch();
  const { loggedIn, loading } = useSelector(authSelector);
  useEffect(() => {
    dispatch(checkUser());
  }, [dispatch]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route
              path="/"
              exact
              render={() => {
                return loggedIn && !loading ? (
                  <Redirect to="/dashboard" />
                ) : (
                  <LoginPage />
                );
              }}
            />
            <Layout>
              {routes.map(({ path, Component, exact }) => (
                <AuthMiddleware
                  key={path}
                  exact={exact || false}
                  path={path}
                  Component={<Component />}
                  loggedIn={loggedIn}
                />
              ))}
            </Layout>
            <Route
              path="*"
              component={() => (
                <Typography variant="h1" align="center">
                  Такой страницы не существует
                </Typography>
              )}
            />
          </Switch>
        </Router>
      </ThemeProvider>
    </div>
  );
};

export default App;
