import { setToken } from "./../../helpers";
import { getMe, login } from "./../../api/requests";
import { Dispatch } from "redux";
import * as actions from "../actions/auth";
import API from "../../api";

export const loginUser = (
  values: { email: string; password: string },
  setError: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: actions.LOGIN_START,
      });
      const response = await login(values);
      setToken(API, response.data.access, response.data.refresh);
      dispatch({
        type: actions.LOGIN_SUCCESS,
        payload: response.data.user,
      });
    } catch (e) {
      setError(true);
      dispatch({
        type: actions.LOGIN_ERROR,
        payload: "error",
      });
    }
  };
};

export const checkUser = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: actions.LOGIN_START,
      });
      const { data } = await getMe();

      dispatch({
        type: actions.LOGIN_SUCCESS,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: actions.LOGIN_ERROR,
        payload: e?.toString(),
      });
    }
  };
};
