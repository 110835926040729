import { getPositions as _getPositions } from "./../../api/requests";
import * as actions from "../actions/positions";
import { Dispatch } from "redux";

export const getPositions = (search: string = "") => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: actions.POSITION_GET_START,
      });
      const { data } = await _getPositions({ search });
      dispatch({
        type: actions.POSITION_GET_SUCCESS,
        payload: {
          data: data.results,
          count: data.count,
        },
      });
    } catch (e) {
      dispatch({
        type: actions.POSITION_GET_ERROR,
        payload: e?.toString(),
      });
    }
  };
};
