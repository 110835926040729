import React from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { Controller } from "react-hook-form";
import { FormHelperText } from "@material-ui/core";

const ReactHookFormSelect = ({
  name,
  label,
  control,
  defaultValue,
  errors,
  error,
  children,
  message,
  ...props
}) => {
  const labelId = `${name}-label`;
  return (
    <FormControl {...props} error={error}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        rules={{
          required: {
            value: true,
            message,
          },
        }}
        render={({ field }) => {
          return (
            <Select {...field} labelId={labelId} label={label}>
              {children}
            </Select>
          );
        }}
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
      <FormHelperText error={error}>
        {errors[name]?.message || ""}
      </FormHelperText>
    </FormControl>
  );
};
export default ReactHookFormSelect;
