import {
  Button,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { deleteBranch, deleteEmployee, deletePosition } from "../api/requests";
import { CLOSE_DELETE_MODAL } from "../redux/actions/deleteModal";
import { deleteModalSelector } from "../redux/selectors";
import CustomModal from "./modal";
import Alert from './Alert'



const DeleteModal = ({ onEnd }: { onEnd?: any }) => {
  const { show, title, deletingObj, id, type } =
    useSelector(deleteModalSelector);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  

  const dispatch = useDispatch();
  const dialogTitle = "Удаление " + (title || "");
  const handleClose = () =>
    dispatch({
      type: CLOSE_DELETE_MODAL,
    });

  const dialogProps = {
    onClose: handleClose,
    open: show,
  };

  const dialogContent = (
    <Typography>Вы действительно хотите удалить {deletingObj} ?</Typography>
  );

  const dialogActions = (
    <>
      <Button
        // onClick={handleClose}
        color="primary"
        variant="outlined"
        startIcon={loading && <CircularProgress color="inherit" size={20} />}
        size="large"
        onClick={() => {
          let request;
          setLoading(true);
          switch (type) {
            case "persons":
              request = deleteEmployee;
              break;
            case "positions":
              request = deletePosition;
              break;
            case "branch":
              request = deleteBranch;
              break;
            default:
              return;
          }
          if (request) {
            request(id)
              .then(() => {})
              .catch(() => {
                setOpen(true);
              })
              .finally(() => {
                setLoading(false);
                handleClose();
                if (onEnd) {
                  onEnd();
                }
              });
          }
        }}
      >
        Подтвердить
      </Button>
      <Button
        size="large"
        onClick={handleClose}
        color="secondary"
        variant="outlined"
      >
        Отмена
      </Button>
    </>
  );

  return (
    <>
      <Alert setOpen={setOpen} type="error" open={open} text="Что-то пошло не так, повторите попытку"   />
      <CustomModal
        dialogActions={dialogActions}
        dialogContent={dialogContent}
        dialogProps={dialogProps}
        dialogTitle={dialogTitle}
      />
    </>
  );
};

export default DeleteModal;
