import EmployeeTable from "../components/eployeeTable";

const PersonsPage = () => {
  return (
    <div>
      <EmployeeTable />
    </div>
  );
};

export default PersonsPage;
