import { Button } from "@material-ui/core";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { sendAllNotifications } from "../api/requests";
import { authSelector, modalSelector } from "../redux/selectors";
import { isStaff } from "../utils/isStaff";
import Alert from "./Alert";
import CustomModal, { ICustomModal } from "./modal";
import { OrganizationDialog, PersonsModal, PositionDialog } from "./modalData";

interface IData {
  [index: string]: ICustomModal;
}

const ButtonList = () => {
  const { user } = useSelector(authSelector);
  const _isStaff = isStaff(user.role)
  const { show, type } = useSelector(modalSelector);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  if(_isStaff) {
    return <></>
  }

  const handleClose = () => {
    dispatch({
      type: "CLOSE",
    });
  };

  const data: IData = {
    persons: { ...PersonsModal({ handleClose }) },
    branch: { ...OrganizationDialog({ handleClose }) },
    position: { ...PositionDialog({ handleClose }) },
  };

  return (
    <div className="buttons">
      <ul className="buttons-list">
        <li className="buttons-list__item">
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              dispatch({
                type: "SHOW",
                payload: {
                  type: "persons",
                },
              });
            }}
          >
            Добавить сотрудника
          </Button>
        </li>
        {user?.role === "admin" ? (
          <li className="buttons-list__item">
            <Button
              variant="contained"
              onClick={() => {
                dispatch({
                  type: "SHOW",
                  payload: {
                    type: "branch",
                  },
                });
              }}
              color="primary"
            >
              Добавить организацию
            </Button>
          </li>
        ) : null}
        <li className="buttons-list__item">
          <Button
            variant="contained"
            onClick={() => {
              dispatch({
                type: "SHOW",
                payload: {
                  type: "position",
                },
              });
            }}
            color="primary"
          >
            Добавить должность
          </Button>
        </li>
        <li className="buttons-list__item">
          <Button
            variant="contained"
            onClick={() => {
              sendAllNotifications().then((res) => {
                setOpen(true);
              });
            }}
            color="primary"
          >
            Отправить оповещение
          </Button>
        </li>
      </ul>
      <Alert
        open={open}
        setOpen={setOpen}
        text="Уведомления отправлены."
        type="success"
      />
      {show ? <CustomModal {...data[type]} /> : null}
    </div>
  );
};

export default ButtonList;
