import { RootState } from "./../reducers/rootReducer";

export const authSelector = (state: RootState) => state.auth;
export const positionsSelector = (state: RootState) => state.positions;
export const employeesSelector = (state: RootState) => state.employees;
export const modalSelector = (state: RootState) => state.modal;
export const deleteModalSelector = (state: RootState) => state.deleteModal;
export const birthdaySelector = (state: RootState) => state.birthdays;
export const archivesSelector = (state: RootState) => state.archives;
export const organizationSelector = (state: RootState) => state.organizations;
export const vacationsSelector = (state: RootState) => state.vacations
export const permissionsSelector = (state: RootState) => state.permissions
export const permissionsModalSelector = (state: RootState) => state.permissionsModal
export const vaccinationsModalSelector = (state: RootState) => state.vaccinationsModal