import { Dispatch } from "redux";
import { getArchives as _getArchives } from "./../../api/requests";
import {
  ARCHIVES_ERROR,
  ARCHIVES_SUCCES,
  ARCHIVES_START,
} from "../actions/archives";

export const getArchives = (
  search: string = "",
  organization_id: number | null | string = null
) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ARCHIVES_START,
    });

    _getArchives({ search, organization_id })
      .then((res) => {
        dispatch({
          type: ARCHIVES_SUCCES,
          payload: {
            data: res.data.results,
            count: res.data.count,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: ARCHIVES_ERROR,
          payload: err.toString(),
        });
      });
  };
};
