import {
  Button,
  Grid,
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Box,
  Typography,
} from "@material-ui/core";
import Select from "react-select";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import DatePicker from "@material-ui/lab/DatePicker";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useForm, Controller } from "react-hook-form";
import {
  addBranch,
  addEmployee,
  addPermission,
  addPosition,
  addVacations,
  editBranch,
  editEmployee,
  editPosition,
  getPermission,
  getPermissionTypes,
  updatePermission,
  updateVacations,
} from "../api/requests";
import { IBranch } from "../types";
import { useDispatch, useSelector } from "react-redux";
import {
  authSelector,
  employeesSelector,
  modalSelector,
  organizationSelector,
  positionsSelector,
  vacationsSelector,
} from "../redux/selectors";
import { useState } from "react";
import { getEmployees } from "../redux/actionCreators/employees";
import { useEffect } from "react";
import ReactHookFormSelect from "./ReactHookFormSelect";
import { months, setFieldErrors } from "../helpers";
import { getPositions } from "../redux/actionCreators/positions";
import { getArchives } from "../redux/actionCreators/archives";
import { getOrganizations } from "../redux/actionCreators/organizations";
import { getDateParsedValues } from "../utils/getDateParsedValues";

export const PersonsModal = ({ handleClose }: { handleClose: () => void }) => {
  const { row, edit, show } = useSelector(modalSelector);
  const organizations = useSelector(organizationSelector);

  const {
    register,
    handleSubmit,
    control,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    shouldUnregister: true,
  });

  useEffect(() => {
    const fields = Object.keys(row);
    if (edit && fields.length && show) {
      reset(row);
    }
  }, [edit, row, show, reset]);

  const { data } = useSelector(positionsSelector);
  const { user } = useSelector(authSelector);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = handleSubmit((values: any, e) => {
    const vacation_date = values.vacation_date ? getDateParsedValues(values.vacation_date) : null;
    const vacation_date_to = values.vacation_date_to ? getDateParsedValues(values.vacation_date_to) : null;
    const date_of_birth = values.date_of_birth ? getDateParsedValues(values.date_of_birth) : null;
    setLoading(true);
    const data = { ...values, date_of_birth, vacation_date, vacation_date_to };
    const resSucces = () => {
      dispatch(row.fired ? getArchives() : getEmployees());
      handleClose();
    };
    const request = edit ? editEmployee(row.id, data) : addEmployee(data);

    request
      .then(() => {
        resSucces();
      })
      .catch((err) => {
        setFieldErrors(err, setError);
      })
      .finally(() => {
        setLoading(false);
      });
  });

  const first_name = register("first_name", {
    required: {
      value: true,
      message: "Пожалуйста введите Имя",
    },
  });
  const last_name = register("last_name", {
    required: {
      value: true,
      message: "Пожалуйста введите Фамилию",
    },
  });
  const middle_name = register("middle_name", {
    required: {
      value: true,
      message: "Пожалуйста введите Отчество",
    },
  });
  const phone_1 = register("phone_1", {
    required: {
      value: true,
      message: "Пожалуйста введите номер телефона",
    },
  });
  const phone_2 = register("phone_2");
  const email = register("email");

  const dialogContent = (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={4}>
        <TextField
          className="w-100p"
          margin="normal"
          variant="outlined"
          label="Имя"
          required
          onChange={first_name.onChange}
          onBlur={first_name.onBlur}
          name={first_name.name}
          inputRef={first_name.ref}
          helperText={errors?.first_name?.message}
          error={errors.first_name ? true : false}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          className="w-100p"
          margin="normal"
          variant="outlined"
          label="Фамилия"
          onChange={last_name.onChange}
          onBlur={last_name.onBlur}
          name={last_name.name}
          inputRef={last_name.ref}
          required
          helperText={errors?.last_name?.message}
          error={errors.last_name ? true : false}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          className="w-100p"
          margin="normal"
          variant="outlined"
          label="Отчество"
          onChange={middle_name.onChange}
          onBlur={middle_name.onBlur}
          name={middle_name.name}
          inputRef={middle_name.ref}
          required
          helperText={errors?.middle_name?.message}
          error={errors.middle_name ? true : false}
        />
      </Grid>
      <Grid xs={12} sm={4} item>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Controller
            control={control}
            defaultValue={null}
            rules={{
              required: {
                message: "Пожалуйста выберите дату рождения",
                value: true,
              },
            }}
            name="date_of_birth"
            render={({ field: { ref, ...rest }, formState: { errors } }) => {
              return (
                <DatePicker
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      helperText={errors?.date_of_birth?.message}
                      error={errors.date_of_birth ? true : false}
                      className="w-100p"
                      margin="normal"
                    />
                  )}
                  inputFormat="dd-MM-yyyy"
                  label="Дата рождения"
                  // value={selectedDate}
                  className="w-100p"
                  mask="__-__-____"
                  {...rest}
                />
              );
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          variant="outlined"
          label="Номер телефона"
          margin="normal"
          onChange={phone_1.onChange}
          onBlur={phone_1.onBlur}
          name={phone_1.name}
          inputRef={phone_1.ref}
          required
          placeholder="+998"
          style={{ width: "100%" }}
          helperText={errors?.phone_1?.message}
          error={errors.phone_1 ? true : false}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          variant="outlined"
          label="Дополнительный телефона"
          onChange={phone_2.onChange}
          onBlur={phone_2.onBlur}
          name={phone_2.name}
          inputRef={phone_2.ref}
          margin="normal"
          placeholder="+998"
          style={{ width: "100%" }}
          helperText={errors?.phone_2?.message}
          error={errors.phone_2 ? true : false}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          className="w-100p"
          margin="normal"
          variant="outlined"
          label="Email"
          onChange={email.onChange}
          onBlur={email.onBlur}
          name={email.name}
          inputRef={email.ref}
          helperText={errors?.email?.message}
          error={errors.email ? true : false}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <ReactHookFormSelect
          errors={errors}
          defaultValue="male"
          message="Пожалуйста выберите пол"
          name="gender"
          control={control}
          label="Пол"
          variant="outlined"
          style={{
            marginTop: 16,
            marginBottom: 8,
            width: "100%",
          }}
          className="w-100p"
          error={Boolean(errors?.gender)}
        >
          <MenuItem value="male">Мужской</MenuItem>
          <MenuItem value="female">Женский</MenuItem>
        </ReactHookFormSelect>
      </Grid>
      {user?.role === "admin" ? (
        <Grid item xs={12} sm={4}>
          <ReactHookFormSelect
            defaultValue={1}
            errors={errors}
            message="Пожалуйста выберите организацию"
            control={control}
            name="organization"
            label="Организация"
            variant="outlined"
            className="formField"
            fullWidth
            error={!!errors.organization}
          >
            {organizations.data.map((branch: any) => (
              <MenuItem value={branch.id} key={branch.id}>
                {branch.name}
              </MenuItem>
            ))}
          </ReactHookFormSelect>
        </Grid>
      ) : null}
      <Grid item xs={12} sm={4}>
        <ReactHookFormSelect
          name="position"
          control={control}
          errors={errors}
          message="Пожалуйста выберите должность"
          defaultValue={""}
          label="Должность"
          variant="outlined"
          className="formField"
          error={Boolean(errors?.position)}
        >
          {data.map((position: { id: string | number; name: string }) => {
            return (
              <MenuItem key={position.id} value={position.id}>
                {position.name}
              </MenuItem>
            );
          })}
        </ReactHookFormSelect>
      </Grid>
      <Grid xs={12} sm={4} item>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Controller
            control={control}
            defaultValue={null}
            name="vacation_date"
            render={({ field: { ref, ...rest }, formState: { errors } }) => {
              return (
                <DatePicker
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      helperText={errors?.vacation_date?.message}
                      error={errors.vacation_date ? true : false}
                      className="w-100p"
                      margin="normal"
                    />
                  )}
                  inputFormat="dd-MM-yyyy"
                  label="Отпуск От"
                  // value={selectedDate}
                  className="w-100p"
                  mask="__-__-____"
                  {...rest}
                />
              );
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid xs={12} sm={4} item>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Controller
            control={control}
            defaultValue={null}
            name="vacation_date_to"
            render={({ field: { ref, ...rest }, formState: { errors } }) => {
              return (
                <DatePicker
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      helperText={errors?.vacation_date_to?.message}
                      error={errors.vacation_date_to ? true : false}
                      className="w-100p"
                      margin="normal"
                    />
                  )}
                  inputFormat="dd-MM-yyyy"
                  label="Отпуск До"
                  // value={selectedDate}
                  className="w-100p"
                  mask="__-__-____"
                  {...rest}
                />
              );
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid xs={12} sm={4} item>
      <Box
          display="flex"
          alignItems="center"
          height="56px"
          margin="16px 0 8px 0"
        >
          <Controller
            control={control}
            defaultValue={true}
            name="is_vaccinated"
            render={({ field }) => {
              return (
                <FormControlLabel
                  control={<Checkbox />}
                  label="Вакцинирован"
                  onChange={(_, checked) => field.onChange(checked)}
                  checked={field.value}
                />
              );
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box
          display="flex"
          alignItems="center"
          height="56px"
          margin="16px 0 8px 0"
        >
          <Controller
            control={control}
            defaultValue={true}
            name="send_notification"
            render={({ field }) => {
              return (
                <FormControlLabel
                  control={<Checkbox />}
                  label="Отправлять уведомление?"
                  onChange={(_, checked) => field.onChange(checked)}
                  checked={field.value}
                />
              );
            }}
          />
        </Box>
      </Grid>
      {edit ? (
        <Grid item xs={12} sm={4}>
          <Box
            display="flex"
            alignItems="center"
            height="56px"
            margin="16px 0 8px 0"
          >
            <Controller
              control={control}
              defaultValue={true}
              name="fired"
              render={({ field }) => {
                return (
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Уволен"
                    onChange={(_, checked) => field.onChange(checked)}
                    checked={field.value}
                  />
                );
              }}
            />
          </Box>
        </Grid>
      ) : null}
    </Grid>
  );

  const dialogTitle = !edit ? "Добавление сотрудника" : "Изменение сотрудника";

  const dialogActions = (
    <>
      <Button
        // onClick={handleClose}
        color="primary"
        variant="outlined"
        type="submit"
        startIcon={loading && <CircularProgress color="inherit" size={20} />}
        size="large"
      >
        Сохранить
      </Button>
      <Button
        size="large"
        onClick={handleClose}
        color="secondary"
        variant="outlined"
      >
        Отмена
      </Button>
    </>
  );

  const dialogProps = {
    open: true,
    onClose: () => {
      handleClose();
      reset({});
    },
  };

  return {
    dialogActions,
    dialogContent,
    dialogProps,
    dialogTitle,
    onSubmit,
  };
};

export const OrganizationDialog = ({
  handleClose,
}: {
  handleClose: () => void;
}) => {
  const dialogTitle = "Добавление организации";

  const [loading, setLoading] = useState(false);
  const { row, edit, show } = useSelector(modalSelector);

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm();

  const onClose = () => {
    handleClose();
    reset({});
  };

  const dispatch = useDispatch();

  const dialogProps = {
    open: true,
    onClose,
  };

  useEffect(() => {
    const fields = Object.keys(row);
    if (edit && fields.length && show) {
      reset(row);
    }
  }, [show, edit, row, reset]);

  const name = register("name", {
    required: {
      message: "Пожалуйста введите название.",
      value: true,
    },
  });
  const description = register("description");
  const phone = register("phone");
  const website = register("website");
  const address = register("address");
  const email = register("email");
  const chat_id = register("chat_id", {
    required: {
      message: "Это обязательное поле.",
      value: true,
    },
  });

  const dialogActions = (
    <>
      <Button
        startIcon={loading && <CircularProgress color="inherit" size={20} />}
        color="primary"
        variant="outlined"
        type="submit"
        size="large"
      >
        Сохранить
      </Button>
      <Button
        size="large"
        onClick={handleClose}
        color="secondary"
        variant="outlined"
      >
        Отмена
      </Button>
    </>
  );

  const onSubmit = handleSubmit((values: IBranch) => {
    setLoading(true);
    const request = edit ? editBranch(row.id, values) : addBranch(values);
    request
      .then(() => {
        dispatch(getOrganizations());
        onClose();
      })
      .catch((err) => {
        setFieldErrors(err, setError);
      })
      .finally(() => {
        setLoading(false);
      });
  });

  const dialogContent = (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          onChange={name.onChange}
          name={name.name}
          onBlur={name.onBlur}
          inputRef={name.ref}
          error={errors.name ? true : false}
          helperText={errors?.name?.message}
          className="w-100p"
          margin="normal"
          variant="outlined"
          label="Название"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          onChange={description.onChange}
          name={description.name}
          onBlur={description.onBlur}
          inputRef={description.ref}
          error={errors.description ? true : false}
          helperText={errors?.description?.message}
          className="w-100p"
          margin="normal"
          variant="outlined"
          label="Описание"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          onChange={address.onChange}
          name={address.name}
          onBlur={address.onBlur}
          inputRef={address.ref}
          error={errors.address ? true : false}
          helperText={errors?.address?.message}
          className="w-100p"
          margin="normal"
          variant="outlined"
          label="Адрес"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          onChange={phone.onChange}
          name={phone.name}
          onBlur={phone.onBlur}
          inputRef={phone.ref}
          error={errors.phone ? true : false}
          helperText={errors?.phone?.message}
          className="w-100p"
          margin="normal"
          variant="outlined"
          label="Номер телефона"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          onChange={email.onChange}
          name={email.name}
          onBlur={email.onBlur}
          inputRef={email.ref}
          error={errors.email ? true : false}
          helperText={errors?.email?.message}
          className="w-100p"
          margin="normal"
          variant="outlined"
          label="Email"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          onChange={website.onChange}
          name={website.name}
          onBlur={website.onBlur}
          inputRef={website.ref}
          error={errors.website ? true : false}
          helperText={errors?.website?.message}
          className="w-100p"
          margin="normal"
          variant="outlined"
          label="Веб сайт"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          onChange={chat_id.onChange}
          name={chat_id.name}
          onBlur={chat_id.onBlur}
          inputRef={chat_id.ref}
          error={errors.chat_id ? true : false}
          helperText={errors?.chat_id?.message}
          className="w-100p"
          margin="normal"
          variant="outlined"
          label="Chat_id(telegram)"
        />
      </Grid>
    </Grid>
  );

  return {
    dialogActions,
    dialogContent,
    dialogProps,
    dialogTitle,
    onSubmit,
  };
};

export const PositionDialog = ({
  handleClose,
}: {
  handleClose: () => void;
}) => {
  const { register, handleSubmit, setError, reset } = useForm();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { edit, row, show } = useSelector(modalSelector);

  const name = register("name", {
    required: {
      value: true,
      message: "Пожалуйста введите название.",
    },
  });

  useEffect(() => {
    const fields = Object.keys(row);
    if (edit && fields.length && show) {
      reset(row);
    }
  }, [show, edit, row, reset]);

  const onSubmit = handleSubmit((values: { name: string }) => {
    setLoading(true);
    const request = edit ? editPosition(row.id, values) : addPosition(values);
    request
      .then(() => {
        handleClose();
      })
      .catch((err) => {
        setFieldErrors(err, setError);
      })
      .finally(() => {
        setLoading(false);
        dispatch(getPositions());
      });
  });

  const dialogTitle = "Добавление должности";

  const dialogProps = {
    open: true,
    onClose: handleClose,
  };

  const dialogActions = (
    <>
      <Button
        startIcon={loading && <CircularProgress color="inherit" size={20} />}
        color="primary"
        variant="outlined"
        type="submit"
        size="large"
      >
        Сохранить
      </Button>
      <Button
        size="large"
        onClick={handleClose}
        color="secondary"
        variant="outlined"
      >
        Отмена
      </Button>
    </>
  );

  const dialogContent = (
    <Grid container>
      <Grid item sm={12}>
        <TextField
          className="w-100p"
          margin="normal"
          variant="outlined"
          label="Название"
          onChange={name.onChange}
          name={name.name}
          onBlur={name.onBlur}
          inputRef={name.ref}
        />
      </Grid>
    </Grid>
  );

  return {
    dialogProps,
    dialogContent,
    dialogTitle,
    dialogActions,
    onSubmit,
  };
};

interface IVacationsDialogProps {
  handleClose: () => void;
  isOpen: boolean;
  selectedMonth: number;
  id: string;
  refetch: () => void;
}

export const VacationsDialog = ({
  handleClose,
  isOpen,
  selectedMonth,
  id,
  refetch,
}: IVacationsDialogProps) => {
  const { data: vacations } = useSelector(vacationsSelector);
  const { data: employees } = useSelector(employeesSelector);
  const { reset, setValue, handleSubmit, watch } = useForm();

  useEffect(() => {
    reset({
      employees:
        vacations?.map(({ id, full_name }) => ({
          label: full_name,
          value: id,
        })) || [],
    });
  }, [vacations, reset, isOpen]);

  const selectedEmployees = watch("employees");

  const dialogTitle = "Отпуск";

  const dialogContent = (
    <div
      style={{
        height: 700,
        width: 500,
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Select
            className="basic-multi-select"
            classNamePrefix="select"
            isMulti
            options={employees.map(({ full_name, id }) => ({
              label: full_name,
              value: id,
            }))}
            value={selectedEmployees}
            onChange={(newValue) => {
              //@ts-ignore
              setValue("employees", newValue);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h4">{months[selectedMonth - 1]}</Typography>
        </Grid>
      </Grid>
    </div>
  );

  const dialogActions = (
    <>
      <Button
        // onClick={handleClose}
        color="primary"
        variant="outlined"
        type="submit"
        // startIcon={loading && <CircularProgress color="inherit" size={20} />}
        size="large"
      >
        Сохранить
      </Button>
      <Button
        size="large"
        onClick={handleClose}
        color="secondary"
        variant="outlined"
      >
        Отмена
      </Button>
    </>
  );

  const dialogProps = {
    open: true,
    onClose: () => {
      handleClose();
      reset({});
    },
  };

  const onSubmit = handleSubmit(async (values: any) => {
    try {
      const employees = values.employees.map((item: any) => item.value);
      if (id) {
        await updateVacations(id, employees);
      } else {
        const date_from = `2022-${selectedMonth}-01`;
        await addVacations(employees, date_from);
      }

      refetch();
    } catch (e) {
      console.log(e);
    } finally {
      handleClose();
    }
  });

  return {
    dialogActions,
    dialogContent,
    dialogProps,
    dialogTitle,
    onSubmit,
  };
};

interface IPermissionsDialogProps {
  handleClose: () => void;
  isOpen: boolean;
  isEditing: boolean;
  id: number;
  refetch: () => void;
  // setSelectedMonth: any
}

interface IPermissionTypes {
  name: string
  id: number
}

export const PermissonDialog = ({
  handleClose,
  isOpen,
  isEditing,
  id,
  refetch,
  // setSelectedMonth
}: IPermissionsDialogProps) => {
  const { data: employees } = useSelector(employeesSelector);
  const {
    reset,
    setValue,
    handleSubmit,
    watch,
    control,
    register,
    formState: { errors },
  } = useForm();

  const selectedEmployees = watch("employees");
  const name = register("name");
  const types = watch("types");
  const [permissionTypes, setPermissionTypes] = useState<IPermissionTypes[]>([])

  useEffect(() => {
    if (isEditing) {
      getPermission(id).then((res: any) => {
        reset({
          employees:
            res.data?.employees?.map((emp: any) => ({
              value: emp.id,
              label: emp.full_name,
            })) || [],
          types:
            res.data?.types?.map((type: any) => ({
              value: type.id,
              label: type.name,
            })) || [],
          date_to: res.data?.date_to,
          date_from: res.data?.date_from,
          name: res.data?.name,
        });
      });
    }

    return () => {
      reset({});
    };
  }, [isEditing, id, reset]);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await getPermissionTypes()
        setPermissionTypes(data.results)
      } catch {
        setPermissionTypes([])
      }
    }
    getData()
  }, [])

  const dialogTitle = isEditing ? "Изменить допуск" : "Создать допуск";

  console.log(types)

  const dialogContent = (
    <div
      style={{
        height: 700,
        width: 700,
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Select
            className="basic-multi-select"
            classNamePrefix="select"
            isMulti
            options={employees.map(({ full_name, id }) => ({
              label: full_name,
              value: id,
            }))}
            value={selectedEmployees}
            onChange={(newValue) => {
              //@ts-ignore
              setValue("employees", newValue);
            }}
          />
        </Grid>
        <Grid xs={12} sm={6} item>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
              control={control}
              defaultValue={null}
              rules={{
                required: {
                  message: "Пожалуйста выберите дату.",
                  value: true,
                },
              }}
              name="date_from"
              render={({ field: { ref, ...rest }, formState: { errors } }) => {
                return (
                  <DatePicker
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        helperText={errors?.date_from?.message}
                        error={errors.date_from ? true : false}
                        className="w-100p"
                        margin="normal"
                        style={{ zIndex: 0 }}
                      />
                    )}
                    inputFormat="dd-MM-yyyy"
                    label="Начало действия допуска"
                    // value={selectedDate}
                    className="w-100p"
                    mask="__-__-____"
                    {...rest}
                  />
                );
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid xs={12} sm={6} item>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
              control={control}
              defaultValue={null}
              rules={{
                required: {
                  message: "Пожалуйста выберите дату.",
                  value: true,
                },
              }}
              name="date_to"
              render={({ field: { ref, ...rest }, formState: { errors } }) => {
                return (
                  <DatePicker
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        helperText={errors?.date_to?.message}
                        error={errors.date_to ? true : false}
                        className="w-100p"
                        margin="normal"
                        style={{ zIndex: 0 }}
                      />
                    )}
                    inputFormat="dd-MM-yyyy"
                    label="Конец действия допуска"
                    // value={selectedDate}
                    className="w-100p"
                    mask="__-__-____"
                    {...rest}
                  />
                );
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12}>
          <TextField
            onChange={name.onChange}
            name={name.name}
            onBlur={name.onBlur}
            inputRef={name.ref}
            error={errors?.name ? true : false}
            helperText={errors?.name?.message}
            className="w-100p"
            margin="normal"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ zIndex: 0 }}
            label="Название"
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            className="basic-multi-select"
            classNamePrefix="select"
            isMulti
            options={permissionTypes.map(({ id, name }) => ({
              label: name,
              value: id,
            }))}
            placeholder="Допуски"
            value={types}
            onChange={(newValue) => {
              //@ts-ignore
              setValue("types", newValue);
            }}
          />
        </Grid>
      </Grid>
    </div>
  );

  const dialogActions = (
    <>
      <Button
        // onClick={handleClose}
        color="primary"
        variant="outlined"
        type="submit"
        // startIcon={loading && <CircularProgress color="inherit" size={20} />}
        size="large"
      >
        Сохранить
      </Button>
      <Button
        size="large"
        onClick={handleClose}
        color="secondary"
        variant="outlined"
      >
        Отмена
      </Button>
    </>
  );

  const dialogProps = {
    open: isOpen,
    onClose: () => {
      handleClose();
      reset({});
    },
  };

  const onSubmit = handleSubmit(async (values: any) => {
    try {
      const employees = values.employees.map((item: any) => item.value);
      const types = values.types.map((item: any) => item.value);
      const date_from = getDateParsedValues(values.date_from);
      const date_to = getDateParsedValues(values.date_to);
      if (isEditing) {
        await updatePermission(employees, date_to, date_from, values.name, id, types);
      } else {
        await addPermission(employees, date_to, date_from, values.name, types);
      }

      refetch();
    } catch (e) {
      console.log(e);
    } finally {
      handleClose();
    }
  });

  return {
    dialogActions,
    dialogContent,
    dialogProps,
    dialogTitle,
    onSubmit,
  };
};
